/* import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
    };
    this.registerName = this.registerName.bind(this);
    this.registerUsername = this.registerUsername.bind(this);
    this.registerEmail = this.registerEmail.bind(this);
    this.registerPassword = this.registerPassword.bind(this);
    this.areFieldsValid = this.areFieldsValid.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  registerName(e) {
    this.setState({ name: e.target.value });
  }
  registerUsername(e) {
    this.setState({ username: e.target.value });
  }
  registerEmail(e) {
    this.setState({ email: e.target.value });
  }
  registerPassword(e) {
    this.setState({ password: e.target.value });
  }

  //const link = 'http://localhost:3000/users/';

  areFieldsValid = () => {
    if (
      this.state.name &&
      this.state.username &&
      this.state.email &&
      this.state.password
    ) {
      return true;
    } else {
      toast.error("Invalid or Empty Fields", {
        position: toast.POSITION.TOP_CENTER,
      });

      return false;
    }
  };

  onSubmit(e) {
    e.preventDefault();
    const register = {
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
    };
    if (this.areFieldsValid()) {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}register`, register)
        .then((res) => {
          if (res.data.success === true) {
            toast.success(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              name: "",
              username: "",
              email: "",
              password: "",
            });
          } else if (res.data.success === false) {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              name: this.state.name,
              username: this.state.username,
              email: this.state.email,
              password: this.state.password,
            });
          } else {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              name: this.state.name,
              username: this.state.username,
              email: this.state.email,
              password: this.state.password,
            });
          }
        });
    }
  }

  render() {
    return (
      <div className="register">
        <section className="section">
          <div className="form">
            <label className="label">Name</label>
            <input
              className="input"
              type="text"
              placeholder="Name"
              value={this.state.name}
              onChange={this.registerName}
            />
            <label className="label">Username</label>
            <input
              className="input"
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={this.registerUsername}
            />
            <label className="label">Email</label>
            <input
              className="input"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.registerEmail}
            />
            <label className="label">Password</label>
            <input
              className="input"
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.registerPassword}
            />
            <br />
            <button
              className="button is-medium is-primary is-fullwidth"
              type="submit"
              onClick={this.onSubmit}
            >
              Register
            </button>
          </div>
        </section>
      </div>
    );
  }
}
 */

import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
  });

  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "email") {
      if (value.trim() === "") {
        setEmailValidationMsg("Email is required.");
      } else if (!isEmailValid(value)) {
        setEmailValidationMsg("Invalid Email Address.");
      } else {
        setEmailValidationMsg("");
      }
    }

    if (name === "password") {
      if (value.trim() === "") {
        setPasswordValidationMsg("Password is required.");
      } else if (!isPasswordValid(value)) {
        setPasswordValidationMsg(
          "Invalid Password length. Password must be at least 8 characters long"
        );
      } else {
        setPasswordValidationMsg("");
      }
    }
  };

  const isEmailValid = (email) => {
    // Regular expression for validating an Email
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const isPasswordValid = (password) => {
    return password?.length >= 8;
  };

  const areFieldsValid = () => {
    const { name, username, email, password } = formData;
    if (!name.trim() || !username.trim() || !email.trim() || !password.trim()) {
      toast.error("Invalid or Empty Fields", {
        position: "top-center",
        theme: "colored",
      });
      return false;
    }
    if (!isEmailValid(email)) {
      toast.error("Invalid Email Address.", {
        position: "top-center",
        theme: "colored",
      });
      return false;
    }
    if (!isPasswordValid(password)) {
      toast.error("Password must be at least 8 characters long!", {
        position: "top-center",
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmittingRequest(true);
    if (!areFieldsValid()) {
      setIsSubmittingRequest(false);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}register`,
        formData
      );
      const { success, msg } = res.data;

      if (success) {
        setIsSubmittingRequest(false);
        toast.success(msg, {
          position: "top-center",
          theme: "colored",
        });
        navigate("/login");
        setFormData({
          name: "",
          username: "",
          email: "",
          password: "",
        });
      } else {
        setIsSubmittingRequest(false);
        toast.error(msg, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      setIsSubmittingRequest(false);
      const response = error.response;
      if (response && response.data) {
        const { code, msg } = response.data;
        if (code) {
          toast.error(`Error ${code}: ${msg}`, {
            position: "top-center",
            theme: "colored",
          });
        } else {
          toast.error(msg, {
            position: "top-center",
            theme: "colored",
          });
        }
      } else {
        toast.error("An unexpected error occurred", {
          position: "top-center",
          theme: "colored",
        });
      }
    } finally {
      setIsSubmittingRequest(false);
    }
  };

  return (
    <div className="register">
      <section className="section">
        <div className="form">
          <label className="label">Name</label>
          <input
            className="input"
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <label className="label">Username</label>
          <input
            className="input"
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
          />
          <label className="label">Email</label>
          <input
            className="input"
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            onFocus={() => {
              if (!isEmailValid(formData.email)) {
                setEmailValidationMsg(
                  "Invalid Email Address. Please use your valid email address for verification."
                );
              }
            }}
            onBlur={() => {
              if (isEmailValid(formData.email)) {
                setEmailValidationMsg("");
              }
            }}
          />
          {emailValidationMsg && (
            <p className="help is-danger">{emailValidationMsg}</p>
          )}
          <label className="label">Password</label>
          <input
            className="input"
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            onFocus={() => {
              if (!isEmailValid(formData.password)) {
                setPasswordValidationMsg(
                  "Password must be at least 8 characters long."
                );
              }
            }}
            onBlur={() => {
              if (isPasswordValid(formData.password)) {
                setPasswordValidationMsg("");
              }
            }}
          />
          {passwordValidationMsg && (
            <p className="help is-danger">{passwordValidationMsg}</p>
          )}
          <br />
          <button
            className={`button is-medium is-primary is-fullwidth ${
              isSubmittingRequest ? "is-loading" : ""
            }`}
            type="submit"
            onClick={onSubmit}
            disabled={isSubmittingRequest}
          >
            {isSubmittingRequest ? "Registering..." : "Register"}
          </button>
        </div>
      </section>
    </div>
  );
};

export default Register;
