/* import React, { Component } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export default class forgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      isDone: false,
    };
  }

  forgotUsername = (e) => {
    this.setState({ username: e.target.value });
  };
  forgotEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  onForgotPassword = (e) => {
    e.preventDefault();
    const doc = {
      username: this.state.username,
      email: this.state.email,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}forgotPassword`, doc)
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ isDone: true });
          toast.success(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        } else if (res.data.success === false) {
          toast.error(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        } else {
          toast.error(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        }
      });

    this.setState({
      username: "",
      email: "",
    });
  };
  render() {
    if (this.state.isDone === true) {
      return <Navigate to="/updatePassword" />;
    }

    return (
      <div className="login">
        <section className="section">
          <div className="form">
            <label className="label">Username</label>
            <input
              className="input"
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={this.forgotUsername}
            />
            <label className="label">Email</label>
            <input
              className="input"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.forgotEmail}
            />
            <br />
            <button
              className="button is-medium is-primary is-fullwidth"
              type="submit"
              onClick={this.onForgotPassword}
            >
              Send Password Reset Request
            </button>
          </div>
        </section>
      </div>
    );
  }
}
 */

import React, { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isDone, setIsDone] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  // Handler for username input change
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  // Handler for email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Function to handle forgot password request
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (!username || !email) {
      setIsSubmitting(false);
      toast.error("Username and email are required.", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}forgotPassword`,
        { username, email }
      );

      if (response.data.success) {
        setIsDone(true);
        toast.success(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error sending password reset request:", error);
      setError("An error occurred while processing your request.");
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
        theme: "colored",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Redirect to updatePassword if the request was successful
  if (isDone) {
    return <Navigate to="/updatePassword" />;
  }

  return (
    <div className="login">
      <section className="section">
        <div className="form">
          <label className="label">Username</label>
          <input
            className="input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleUsernameChange}
            disabled={isSubmitting}
          />
          <label className="label">Email</label>
          <input
            className="input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            disabled={isSubmitting}
          />
          <br />
          <button
            className="button is-medium is-primary is-fullwidth"
            type="submit"
            onClick={handleForgotPassword}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Send Password Reset Request"}
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
