/* // src/components/DynamicMatchTables.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

const DynamicMatchTables = () => {
  const [tables, setTables] = useState([]); // Saved tables from the server
  const [newTables, setNewTables] = useState([]); // New tables to be added
  const [teams, setTeams] = useState([]); // Available teams for selection
  const [allTeams, setAllTeams] = useState([]); // All teams fetched from API
  const [newTable, setNewTable] = useState({
    stageType: "",
    header: "",
    time: "",
    ground1: [],
    ground2: [],
  });

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teams = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setAllTeams(teams);
        setTeams(teams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    // Fetch saved tables from the API
    const fetchTables = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}getMatchTables`
        );
        setTables(response.data);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };
    fetchTables();
  }, []);

  const handleAddTable = () => {
    if (
      !newTable.stageType ||
      !newTable.header ||
      !newTable.time ||
      newTable.ground1.length === 0 ||
      newTable.ground2.length === 0
    ) {
      alert("Please fill out all fields and select teams for both grounds.");
      return;
    }

    // Add the new table to the newTables state
    setNewTables((prevNewTables) => [...prevNewTables, newTable]);

    // Add the new table to the UI immediately
    setTables((prevTables) => [...prevTables, newTable]);

    // Clear the new table form
    setNewTable({
      stageType: "",
      header: "",
      time: "",
      ground1: [],
      ground2: [],
    });
    updateAvailableTeams(newTable.ground1.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevNewTable) => ({ ...prevNewTable, [name]: value }));
  };

  const handleGroundChange = (name, selectedOptions) => {
    setNewTable((prevNewTable) => ({
      ...prevNewTable,
      [name]: selectedOptions || [],
    }));
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    if (newTables.length === 0) {
      alert("No new tables to save.");
      return;
    }
    try {
      // Post only the new tables to the server
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}saveMatchTables`, {
        tables: newTables,
      });
      setNewTables([]); // Clear the newTables state after saving
      alert("New tables saved successfully!");
    } catch (error) {
      console.error("Error saving tables:", error);
      alert("Failed to save new tables.");
    }
  };

  return (
    <div className="container">
      <div className="box">
        <h2 className="title is-4">Add New Match Table</h2>
        <div className="field">
          <label className="label">Stage Type</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="stageType"
              value={newTable.stageType}
              onChange={handleInputChange}
              placeholder="Enter stage type (e.g., Group Stage, Quarterfinals)"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Header</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="header"
              value={newTable.header}
              onChange={handleInputChange}
              placeholder="Enter table header"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Time</label>
          <div className="control">
            <input
              className="input"
              type="time"
              name="time"
              value={newTable.time}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Ground 1 Teams (Select teams)</label>
          <div className="control">
            <Select
              isMulti
              name="ground1"
              options={teams}
              value={newTable.ground1}
              onChange={(selectedOptions) =>
                handleGroundChange("ground1", selectedOptions)
              }
              placeholder="Select teams for Ground 1"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Ground 2 Teams (Select teams)</label>
          <div className="control">
            <Select
              isMulti
              name="ground2"
              options={teams}
              value={newTable.ground2}
              onChange={(selectedOptions) =>
                handleGroundChange("ground2", selectedOptions)
              }
              placeholder="Select teams for Ground 2"
            />
          </div>
        </div>
        <button className="button is-primary" onClick={handleAddTable}>
          Add Table
        </button>
        <button className="button is-link mt-3" onClick={handleSaveTables}>
          Save New Tables
        </button>
      </div>

      <div className="mt-5">
        <h2 className="title is-4">Match Tables</h2>
        {tables.length > 0 ? (
          <div className="table-container">
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Stage Type</th>
                  <th>Header</th>
                  <th>Time</th>
                  <th>Ground 1</th>
                  <th>Ground 2</th>
                </tr>
              </thead>
              <tbody>
                {tables.map((table, index) => (
                  <tr key={index}>
                    <td>{table.stageType}</td>
                    <td>{table.header}</td>
                    <td>{table.time}</td>
                    <td>
                      {table.ground1.map((team) => team.label).join(", ")}
                    </td>
                    <td>
                      {table.ground2.map((team) => team.label).join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No tables found.</p>
        )}
      </div>
    </div>
  );
};

export default DynamicMatchTables;
 */

// src/components/DynamicMatchTables.js
/* import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

const DynamicMatchTables = () => {
  const [tables, setTables] = useState([]); // Saved tables from the server
  const [newTables, setNewTables] = useState([]); // New tables to be added
  const [teams, setTeams] = useState([]); // Available teams for selection
  const [allTeams, setAllTeams] = useState([]); // All teams fetched from API
  const [newTable, setNewTable] = useState({
    stageType: "",
    time: "",
    ground1: [],
    ground2: [],
  });

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teams = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setAllTeams(teams);
        setTeams(teams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    // Fetch saved tables from the API
    const fetchTables = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}getMatchTables`
        );
        setTables(response.data);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };
    fetchTables();
  }, []);

  const handleAddTable = () => {
    if (
      !newTable.stageType ||
      !newTable.time ||
      newTable.ground1.length === 0 ||
      newTable.ground2.length === 0
    ) {
      alert("Please fill out all fields and select teams for both grounds.");
      return;
    }

    let updatedTables = [...tables];
    let stageTypeChanged = false;

    if (
      updatedTables.length === 0 ||
      updatedTables[updatedTables.length - 1].stageType !== newTable.stageType
    ) {
      stageTypeChanged = true;
      updatedTables.push({
        stageType: newTable.stageType,
        time: "",
        ground1: [],
        ground2: [],
      });
    }

    updatedTables.push({
      stageType: "", // Empty stageType for this row
      time: newTable.time,
      ground1: newTable.ground1,
      ground2: newTable.ground2,
    });

    setTables(updatedTables);
    setNewTables([...newTables, newTable]);

    setNewTable({
      stageType: "",
      time: "",
      ground1: [],
      ground2: [],
    });
    updateAvailableTeams(newTable.ground1.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevNewTable) => ({ ...prevNewTable, [name]: value }));
  };

  const handleGroundChange = (name, selectedOptions) => {
    setNewTable((prevNewTable) => ({
      ...prevNewTable,
      [name]: selectedOptions || [],
    }));
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    if (newTables.length === 0) {
      alert("No new tables to save.");
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}saveMatchTables`, {
        tables: newTables,
      });
      setNewTables([]); // Clear the newTables state after saving
      alert("New tables saved successfully!");
    } catch (error) {
      console.error("Error saving tables:", error);
      alert("Failed to save new tables.");
    }
  };

  const renderTableRows = () => {
    let lastStageType = "";
    return tables.map((table, index) => {
      let row;
      if (table.stageType && table.stageType !== lastStageType) {
        lastStageType = table.stageType;
        row = (
          <React.Fragment key={`stage-${index}`}>
            <tr>
              <td colSpan="3" className="has-text-centered">
                <strong>{table.stageType}</strong>
              </td>
            </tr>
            <tr key={`row-${index}`}>
              <td>{table.time}</td>
              <td>{table.ground1.map((team) => team.label).join(" vs ")}</td>
              <td>{table.ground2.map((team) => team.label).join(" vs ")}</td>
            </tr>
          </React.Fragment>
        );
      } else if (table.time) {
        row = (
          <tr key={`row-${index}`}>
            <td>{table.time}</td>
            <td>{table.ground1.map((team) => team.label).join(" vs ")}</td>
            <td>{table.ground2.map((team) => team.label).join(" vs ")}</td>
          </tr>
        );
      }
      return row;
    });
  };

  return (
    <div className="container">
      <div className="box">
        <h2 className="title is-4">Add New Match Table</h2>
        <div className="field">
          <label className="label">Stage Type</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="stageType"
              value={newTable.stageType}
              onChange={handleInputChange}
              placeholder="Enter stage type (e.g., Group Stage, Quarterfinals)"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Time</label>
          <div className="control">
            <input
              className="input"
              type="time"
              name="time"
              value={newTable.time}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Ground 1 Teams (Select teams)</label>
          <div className="control">
            <Select
              isMulti
              name="ground1"
              options={teams}
              value={newTable.ground1}
              onChange={(selectedOptions) =>
                handleGroundChange("ground1", selectedOptions)
              }
              placeholder="Select teams for Ground 1"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Ground 2 Teams (Select teams)</label>
          <div className="control">
            <Select
              isMulti
              name="ground2"
              options={teams}
              value={newTable.ground2}
              onChange={(selectedOptions) =>
                handleGroundChange("ground2", selectedOptions)
              }
              placeholder="Select teams for Ground 2"
            />
          </div>
        </div>
        <button className="button is-primary" onClick={handleAddTable}>
          Add Table
        </button>
        <button className="button is-link mt-3" onClick={handleSaveTables}>
          Save New Tables
        </button>
      </div>

      <div className="mt-5">
        <h2 className="title is-4">Match Tables</h2>
        {tables.length > 0 ? (
          <div className="table-container">
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Ground 1</th>
                  <th>Ground 2</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        ) : (
          <p>No tables found.</p>
        )}
      </div>
    </div>
  );
};

export default DynamicMatchTables; */

/* import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

const DynamicMatchTables = ({ allRegisteredTeams }) => {
  const [tables, setTables] = useState([]); // Saved tables from the server
  const [newTables, setNewTables] = useState([]); // New tables to be added
  const [teams, setTeams] = useState([]); // Available teams for selection
  const [allTeams, setAllTeams] = useState([]); // All teams fetched from API
  const [newTable, setNewTable] = useState({
    stageType: "",
    time: "",
    ground1: [],
    ground2: [],
  });

  const { isUserAuthenticated, isUserAdmin } = useAuth();

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      setAllTeams(allRegisteredTeams);
      setTeams(allRegisteredTeams);
    };
    fetchTeams();
  }, [allRegisteredTeams]);

  useEffect(() => {
    // Fetch saved tables from the API
    const fetchTables = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}getMatchTables`
        );
        setTables(response.data);
      } catch (error) {
        toast.error("Error fetching tables", {
          position: "top-center",
          theme: "colored",
        });
        console.error("Error fetching tables:", error);
      }
    };
    fetchTables();
  }, []);

  const handleAddTable = () => {
    if (
      !newTable.stageType ||
      !newTable.time ||
      (newTable.ground1.length === 0 && newTable.stageType !== "Final") ||
      (newTable.ground2.length === 0 && newTable.stageType !== "Final")
    ) {
      toast.error(
        "Please fill out all fields and select teams for both grounds.",
        {
          position: "top-center",
          theme: "colored",
        }
      );
      return;
    }

    const normalizedStageType = newTable.stageType.trim().toLowerCase();
    let updatedTables = [...tables];
    let stageTypeChanged = false;

    if (
      updatedTables.length === 0 ||
      updatedTables[updatedTables.length - 1].stageType.toLowerCase() !==
        normalizedStageType
    ) {
      stageTypeChanged = true;
      updatedTables.push({
        stageType: newTable.stageType,
        time: "",
        ground1: [],
        ground2: [],
      });
    }

    updatedTables.push({
      stageType: "", // Empty stageType for this row
      time: newTable.time,
      ground1: newTable.ground1, // Keep this as array of objects
      ground2: newTable.ground2, // Keep this as array of objects
    });

    setTables(updatedTables);
    setNewTables([...newTables, newTable]);

    setNewTable({
      stageType: "",
      time: "",
      ground1: [],
      ground2: [],
    });
    updateAvailableTeams(newTable.ground1.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevNewTable) => ({ ...prevNewTable, [name]: value }));
  };

  const handleGroundChange = (name, selectedOptions) => {
    setNewTable((prevNewTable) => ({
      ...prevNewTable,
      [name]: selectedOptions || [],
    }));
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    if (newTables.length === 0) {
      toast.error("No new tables to save.", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }
    const token = localStorage.getItem("usertoken");
    const userToken = JSON.parse(token);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}saveMatchTables`,
        {
          tables: newTables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        }
      );
      setNewTables([]); // Clear the newTables state after saving
      toast.success("New tables saved successfully!", {
        position: "top-center",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error saving tables:", error);
      toast.error("Failed to save new tables.", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const renderTableRows = () => {
    let lastStageType = "";
    return tables.map((table, index) => {
      const normalizedStageType = table.stageType.toLowerCase();
      let row;
      if (
        table.stageType &&
        normalizedStageType !== lastStageType.toLowerCase()
      ) {
        lastStageType = table.stageType;
        row = (
          <React.Fragment key={`stage-${index}`}>
            <tr>
              <td colSpan="4" className="has-text-centered">
                <strong>{table.stageType}</strong>
              </td>
            </tr>
            <tr key={`row-${index}`}>
              <td>{table.time}</td>
              <td>
                <strong>
                  {table.ground1.map((team) => team.label).join(" vs ")}
                </strong>
              </td>
              <td>
                <strong>
                  {table.ground2.map((team) => team.label).join(" vs ")}
                </strong>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (table.time) {
        row = (
          <tr key={`row-${index}`}>
            <td>{table.time}</td>
            <td>
              <strong>
                {table.ground1.map((team) => team.label).join(" vs ")}
              </strong>
            </td>
            <td>
              <strong>
                {table.ground2.map((team) => team.label).join(" vs ")}
              </strong>
            </td>
          </tr>
        );
      }
      return row;
    });
  };

  return (
    <div className="container">
      {isUserAuthenticated() && isUserAdmin() && (
        <div className="box">
          <h2 className="title is-4">Add New Game Schedule</h2>
          <div className="field">
            <label className="label">Stage Type</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="stageType"
                value={newTable.stageType}
                onChange={handleInputChange}
                placeholder="Enter stage type (e.g., Group Stage, Quarterfinals)"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Time</label>
            <div className="control">
              <input
                className="input"
                type="time"
                name="time"
                value={newTable.time}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Ground 1 Teams (Select teams)</label>
            <div className="control">
              <Select
                isMulti
                name="ground1"
                options={teams}
                value={newTable.ground1}
                onChange={(selectedOptions) =>
                  handleGroundChange("ground1", selectedOptions)
                }
                placeholder="Select teams for Ground 1"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Ground 2 Teams (Select teams)</label>
            <div className="control">
              <Select
                isMulti
                name="ground2"
                options={teams}
                value={newTable.ground2}
                onChange={(selectedOptions) =>
                  handleGroundChange("ground2", selectedOptions)
                }
                placeholder="Select teams for Ground 2"
              />
            </div>
          </div>
          <button
            className="button is-primary"
            onClick={handleAddTable}
            style={{ marginBottom: "0.5rem", width: "100%" }}
          >
            Add Table
          </button>
          <button
            className="button is-link mt-3"
            onClick={handleSaveTables}
            style={{ width: "100%" }}
          >
            Save New Tables
          </button>
        </div>
      )}

      <div className="mt-5">
        <h2
          className="title is-4 tiesheet-title"
          style={{ textAlign: "center", textDecoration: "underline" }}
        >
          Game Schedule
        </h2>
        {tables.length > 0 ? (
          <div className="table-container">
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Time</th>
                  <th style={{ textAlign: "center" }}>Ground 1</th>
                  <th style={{ textAlign: "center" }}>Ground 2</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>No tables found.</p>
        )}
      </div>
    </div>
  );
};

export default DynamicMatchTables; */

import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

const DynamicMatchTables = ({ allRegisteredTeams }) => {
  const [tables, setTables] = useState([]); // Saved tables from the server
  const [newTables, setNewTables] = useState([]); // New tables to be added
  const [teams, setTeams] = useState([]); // Available teams for selection
  const [allTeams, setAllTeams] = useState([]); // All teams fetched from API
  const [newTable, setNewTable] = useState({
    stageType: "",
    time: "",
    ground1: [],
    ground2: [],
  });

  const { isUserAuthenticated, isUserAdmin } = useAuth();

  useEffect(() => {
    // Fetch teams from the provided allRegisteredTeams prop
    setAllTeams(allRegisteredTeams);
    setTeams(allRegisteredTeams);
  }, [allRegisteredTeams]);

  useEffect(() => {
    // Fetch saved tables from the API
    const fetchTables = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}getMatchTables`
        );
        setTables(response.data);
      } catch (error) {
        toast.error("Error fetching tables", {
          position: "top-center",
          theme: "colored",
        });
        console.error("Error fetching tables:", error);
      }
    };
    fetchTables();
  }, []);

  const handleAddTable = () => {
    if (
      !newTable.stageType ||
      !newTable.time ||
      (newTable.ground1.length === 0 && newTable.stageType !== "Final") ||
      (newTable.ground2.length === 0 && newTable.stageType !== "Final")
    ) {
      toast.error(
        "Please fill out all fields and select teams for both grounds.",
        {
          position: "top-center",
          theme: "colored",
        }
      );
      return;
    }

    const normalizedStageType = newTable.stageType.trim().toLowerCase();
    let updatedTables = [...tables];
    let stageTypeChanged = false;

    if (
      updatedTables.length === 0 ||
      updatedTables[updatedTables.length - 1].stageType.toLowerCase() !==
        normalizedStageType
    ) {
      stageTypeChanged = true;
      updatedTables.push({
        stageType: newTable.stageType,
        time: "",
        ground1: [],
        ground2: [],
      });
    }

    updatedTables.push({
      stageType: "", // Empty stageType for this row
      time: newTable.time,
      ground1: newTable.ground1, // Keep this as array of objects
      ground2: newTable.ground2, // Keep this as array of objects
    });

    setTables(updatedTables);
    setNewTables([...newTables, newTable]);

    setNewTable({
      stageType: "",
      time: "",
      ground1: [],
      ground2: [],
    });
    updateAvailableTeams(newTable.ground1.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevNewTable) => ({ ...prevNewTable, [name]: value }));
  };

  const handleGroundChange = (name, selectedOptions) => {
    setNewTable((prevNewTable) => ({
      ...prevNewTable,
      [name]: selectedOptions || [],
    }));
  };

  const handleCreateOption = (newValue) => {
    const newOption = { value: newValue, label: newValue };
    setAllTeams((prevTeams) => [...prevTeams, newOption]);
    setTeams((prevTeams) => [...prevTeams, newOption]);
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    if (newTables.length === 0) {
      toast.error("No new tables to save.", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }
    const token = localStorage.getItem("usertoken");
    const userToken = JSON.parse(token);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}saveMatchTables`,
        {
          tables: newTables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        }
      );
      setNewTables([]); // Clear the newTables state after saving
      toast.success("New tables saved successfully!", {
        position: "top-center",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error saving tables:", error);
      toast.error("Failed to save new tables.", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const renderTableRows = () => {
    let lastStageType = "";
    return tables.map((table, index) => {
      const normalizedStageType = table.stageType.toLowerCase();
      let row;
      if (
        table.stageType &&
        normalizedStageType !== lastStageType.toLowerCase()
      ) {
        lastStageType = table.stageType;
        row = (
          <React.Fragment key={`stage-${index}`}>
            <tr>
              <td colSpan="4" className="has-text-centered">
                <strong>{table.stageType}</strong>
              </td>
            </tr>
            <tr key={`row-${index}`}>
              <td>{table.time}</td>
              <td>
                <strong>
                  {table.ground1.map((team) => team.label).join(" vs ")}
                </strong>
              </td>
              <td>
                <strong>
                  {table.ground2.map((team) => team.label).join(" vs ")}
                </strong>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (table.time) {
        row = (
          <tr key={`row-${index}`}>
            <td>{table.time}</td>
            <td>
              <strong>
                {table.ground1.map((team) => team.label).join(" vs ")}
              </strong>
            </td>
            <td>
              <strong>
                {table.ground2.map((team) => team.label).join(" vs ")}
              </strong>
            </td>
          </tr>
        );
      }
      return row;
    });
  };

  return (
    <div className="container">
      {isUserAuthenticated() && isUserAdmin() && (
        <div className="box">
          <h2 className="title is-4">Add New Game Schedule</h2>
          <div className="field">
            <label className="label">Stage Type</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="stageType"
                value={newTable.stageType}
                onChange={handleInputChange}
                placeholder="Enter stage type (e.g., Group Stage, Quarterfinals)"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Time (e.g., 09:30 - 10:30)</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="time"
                value={newTable.time}
                onChange={handleInputChange}
                placeholder="Enter time range (e.g., 09:30 - 10:30)"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Ground 1 Teams (Select teams)</label>
            <div className="control">
              <Creatable
                isMulti
                name="ground1"
                options={teams}
                value={newTable.ground1}
                onChange={(selectedOptions) =>
                  handleGroundChange("ground1", selectedOptions)
                }
                onCreateOption={handleCreateOption}
                placeholder="Select or create teams for Ground 1"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Ground 2 Teams (Select teams)</label>
            <div className="control">
              <Creatable
                isMulti
                name="ground2"
                options={teams}
                value={newTable.ground2}
                onChange={(selectedOptions) =>
                  handleGroundChange("ground2", selectedOptions)
                }
                onCreateOption={handleCreateOption}
                placeholder="Select or create teams for Ground 2"
              />
            </div>
          </div>
          <button
            className="button is-primary"
            onClick={handleAddTable}
            style={{ marginBottom: "0.5rem", width: "100%" }}
          >
            Add Table
          </button>
          <button
            className="button is-link mt-3"
            onClick={handleSaveTables}
            style={{ width: "100%" }}
          >
            Save New Tables
          </button>
        </div>
      )}

      <div className="mt-5">
        <h2
          className="title is-4 tiesheet-title"
          style={{ textAlign: "center", textDecoration: "underline" }}
        >
          Game Schedule
        </h2>
        {tables.length > 0 ? (
          <div className="table-container">
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Time</th>
                  <th style={{ textAlign: "center" }}>Ground 1</th>
                  <th style={{ textAlign: "center" }}>Ground 2</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>No tables found.</p>
        )}
      </div>
    </div>
  );
};

export default DynamicMatchTables;
