/* import React, { Component } from "react";
import axios from "axios";
import { Navigate, Link, useHistory } from "react-router-dom";
import Loader from "./loader";
import Auth from "../auth/auth";

export default class events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      allEvents: [],
    };
  }

  goback = () => {
    useHistory.goBack();
  };

  componentDidMount() {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/allevents`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          const totalevents = res.data;

          this.setState({
            allEvents: totalevents.events,
            isLoading: false,
          });
        });
    }
  }

  render() {
    if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
      return <Navigate to="/login" />;
    }
    const { allEvents, isLoading } = this.state;
    let loader = isLoading && <Loader />;
    let warningMessage =
      /!isLoading && allEvents.length <= 0 && "No items Found";

    return (
      <div id="eventsPage">
        {loader}
        {warningMessage}
        {allEvents &&
          allEvents.map((event, i) => (
            <div className="eCard" key={i} id="eventCard">
              <div className="card">
                <div className="card-content">
                  <p className="title">Event Details</p>
                  <hr />
                  <p className="subtitle">
                    Event Name: <strong>{event.eventName}</strong>
                  </p>
                  <p className="subtitle">
                    Event Date: <strong>{event.date}</strong>
                  </p>
                  <p className="subtitle">
                    Start Time: <strong>{event.time} AM</strong>
                  </p>
                  <p className="subtitle">
                    Location: <strong>{event.place}</strong>
                  </p>
                  <p className="subtitle">
                    Due Date For Registration: <strong>{event.dueDate}</strong>
                  </p>
                  <button
                    className="button is-primary"
                    style={{ marginRight: "1rem" }}
                  >
                    <Link to={"/registerTeam/" + event._id}>
                      Register Your Team
                    </Link>
                  </button>
                  <button
                    className="button is-warning"
                    type="submit"
                    onClick={() => {
                      this.goback();
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
 */

/* import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, Link, useNavigate } from "react-router-dom";
import Loader from "./loader";
import Auth from "../auth/auth";

const Events = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/allevents`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          const totalevents = res.data;

          setAllEvents(totalevents.events);
          setIsLoading(false);
        });
    }
  }, []);

  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  const loader = isLoading && <Loader />;
  const warningMessage =
    !isLoading && allEvents.length <= 0 && "No items Found";

  return (
    <div id="eventsPage" style={{ margin: "7.7rem auto 3rem auto" }}>
      {loader}
      {warningMessage}
      {allEvents &&
        allEvents.map((event, i) => (
          <div className="eCard" key={i} id="eventCard">
            <div className="card">
              <div className="card-content">
                <p className="title">Event Details</p>
                <hr />
                <p className="subtitle">
                  Event Name: <strong>{event.eventName}</strong>
                </p>
                <p className="subtitle">
                  Event Date: <strong>{event.date}</strong>
                </p>
                <p className="subtitle">
                  Start Time: <strong>{event.time} AM</strong>
                </p>
                <p className="subtitle">
                  Location: <strong>{event.place}</strong>
                </p>
                <p className="subtitle">
                  Due Date For Registration: <strong>{event.dueDate}</strong>
                </p>
                <button
                  className="button is-link"
                  style={{ marginRight: "1rem" }}
                >
                  <Link
                    to={"/registerTeam/" + event._id}
                    style={{ color: "#fff" }}
                  >
                    Register Your Team
                  </Link>
                </button>
                <button
                  className="button is-warning"
                  type="submit"
                  onClick={goBack}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Events; */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, Link, useNavigate } from "react-router-dom";
import Loader from "./loader";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../contexts/AuthContext";

const Events = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const navigate = useNavigate();

  const { isUserAuthenticated } = useAuth();
  const isAuthenticatedUser = isUserAuthenticated();

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}allevents`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          const totalevents = res.data;

          setAllEvents(totalevents.events);
          setIsLoading(false);
        });
    }
  }, []);

  if (!isAuthenticatedUser) {
    return <Navigate to="/login" />;
  }

  const loader = isLoading && <Loader />;
  const warningMessage =
    !isLoading && allEvents.length <= 0 && "No items Found";

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}, ${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;
  };

  const renderEventCard = (event, i) => {
    const dueDate = new Date(event.dueDate);
    const isDueDatePassed = dueDate < new Date();

    return (
      <div
        className="eCard"
        key={i}
        id="eventCard"
        style={
          isMobile
            ? { margin: "1rem", paddingTop: "2rem" }
            : { margin: "2rem", padding: "2rem" }
        }
      >
        <div className="card">
          <div className="card-content">
            <p className="title">Event Details</p>
            <hr />
            <p className="subtitle">
              Event Name: <strong>{event.eventName}</strong>
            </p>
            <p className="subtitle">
              Event Date: <strong>{event.date}</strong>
            </p>
            <p className="subtitle">
              Start Time: <strong>{event.time} AM</strong>
            </p>
            <p className="subtitle">
              Location: <strong>{event.place}</strong>
            </p>
            <p className="subtitle">
              Due Date For Registration:{" "}
              <strong>{formatDate(event.dueDate)}</strong>
            </p>
            {isDueDatePassed ? (
              <button
                className="button is-link"
                style={{ marginRight: "1rem" }}
                disabled
              >
                Register Your Team
              </button>
            ) : (
              <button
                className="button is-link"
                style={{ marginRight: "1rem" }}
              >
                <Link
                  to={"/registerTeam/" + event._id}
                  style={{ color: "#fff" }}
                >
                  Register Your Team
                </Link>
              </button>
            )}
            <button
              className="button is-warning"
              type="button"
              onClick={goBack}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="eventsPage"
      style={{
        margin: "4.7rem auto",
        padding: isMobile ? "0" : "3rem",
      }}
    >
      {loader}
      {warningMessage}
      {allEvents && allEvents.map((event, i) => renderEventCard(event, i))}
    </div>
  );
};

export default Events;
