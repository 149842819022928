import React from "react";

const NimenhuutoCalendarWidget = () => {
  return (
    <div style={{ width: "98%" }}>
      <div
        className="calendarIframe"
        style={{
          textAlign: "center",
          backgroundColor: "#fff",
          border: "0px",
          padding: "0px",
          margin: "0px",
        }}
      >
        <div>
          <a
            href="https://varsinais-suomennepalin.nimenhuuto.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "1rem",
              textDecoration: "none",
              color: "#444",
            }}
          >
            <strong>Varsinais-Suomen Nepalin Monthly Calendar</strong>
          </a>
          <div>
            »{" "}
            <a
              style={{ color: "#444", fontSize: "14px" }}
              href="https://varsinais-suomennepalin.nimenhuuto.com/calendar/monthly"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Calendar</strong>
            </a>{" "}
            ·{" "}
            <a
              style={{ color: "#444", fontSize: "14px" }}
              href="https://varsinais-suomennepalin.nimenhuuto.com/player"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Responses</strong>
            </a>
          </div>
        </div>

        <iframe
          style={{
            width: "100%",
            height: "450px",
            border: "none",
            padding: "0px",
            margin: "10px 0px 10px 0px",
            allowtransparency: "true",
          }}
          frameBorder="0"
          src="https://varsinais-suomennepalin.nimenhuuto.com/calendar/widget_iframe_monthly_calendar?css=&height=550"
          scrolling="auto"
          loading="lazy"
          title="Monthly Calendar"
        ></iframe>
      </div>
    </div>
  );
};

export default NimenhuutoCalendarWidget;
