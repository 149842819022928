/* import React, { Component } from "react";
import { Link } from "react-router-dom";
import vsnulogo from "../assests/images/vsnulogo.jpeg";
import Auth from "../auth/auth";
import ThemeToggleButton from "./themeToggleButton";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isactive: false,
      isMenuActive: false,
      prevScrollPos: window.pageYOffset,
      visible: true,
    };
  }

  handleScroll = () => {
    const { prevScrollPos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos;

    this.setState({
      prevScrollPos: currentScrollPos,
      visible,
    });
  };

  handleClick = () => {
    this.setState({ isactive: true });
  };

  handleNavBurgerMenuToggle = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive });
  };

  closeDropDownNavMenu = () => {
    this.setState({ isMenuActive: false });
  };

  handleClickOutsideNavDropMenu = () => {
    const concernedElement1 = document.getElementById("navMenu");
    const concernedElement2 = document.getElementById("burger");

    document.addEventListener("mousedown", (e) => {
      // if (!concernedElement.contains(e.target)) {
        //this.closeDropDownNavMenu();
        //console.log("clicked outside");
      //}
      if (
        concernedElement1.contains(e.target) ||
        concernedElement2.contains(e.target)
      ) {
        console.log("");
      } else {
        //this.closeDropDownNavMenu();
        //this.handleNavBurgerMenuToggle();
        this.setState({ isMenuActive: false });
        //console.log("Clicked Outside / Elsewhere");
      }
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleClickOutsideNavDropMenu();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener(
      "mousedown",
      this.handleClickOutsideNavDropMenu(),
      false
    );
  }

  render() {
    const { visible } = this.state;
    return (
      <div className="container">
        <nav
          className={`navbar ${
            visible
              ? "is-transparent is-fixed-top"
              : "is-fixed-top is-scrolling"
          }`}
          //className="navbar is-transparent is-fixed-top"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <img src={vsnulogo} alt="" width="90" height="7" loading="lazy" />
            <Link to={"/"} className="navbar-item">
              <span className="clubName" style={{ fontSize: "1.2rem" }}>
                Varsinais-Suomen<br></br>Nepalin Urheiluyhdistys ry
              </span>
            </Link>

            <div className="navbar-item">
              <ThemeToggleButton />
            </div>

            <div
              role="button"
              className={`navbar-burger burger ${
                this.state.isMenuActive ? "is-active" : null
              }`}
              id="burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navMenu"
              style={{ marginTop: "auto", marginBottom: "auto" }}
              onClick={this.handleNavBurgerMenuToggle}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div
            className={`navbar-menu ${
              this.state.isMenuActive ? "is-active" : null
            }`}
            id="navMenu"
          >
            <div className="navbar-end">
              <Link
                to={"/"}
                className="navbar-item"
                onClick={this.closeDropDownNavMenu}
              >
                Home
              </Link>
              <Link
                to={"/about"}
                className="navbar-item"
                onClick={this.closeDropDownNavMenu}
              >
                About
              </Link>
              {Auth.isUserAuthenticated() && (
                <Link
                  to={"/Profile"}
                  className="navbar-item"
                  onClick={this.closeDropDownNavMenu}
                >
                  Profile
                </Link>
              )}
              {!Auth.isUserAuthenticated() && (
                <Link
                  to={"/register"}
                  className="navbar-item"
                  onClick={this.closeDropDownNavMenu}
                >
                  Register
                </Link>
              )}
              {!Auth.isUserAuthenticated() && (
                <Link
                  to={"/login"}
                  className="navbar-item"
                  onClick={this.closeDropDownNavMenu}
                >
                  Log in
                </Link>
              )}
              {Auth.isUserAuthenticated() && (
                <Link
                  to={"/logout"}
                  className="navbar-item"
                  onClick={this.closeDropDownNavMenu}
                >
                  Log Out
                </Link>
              )}
              <Link to={"/manage"} className="navbar-item">
                Manage
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
 */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import vsnulogo from "../assests/images/vsnulogo.jpeg";
import { useAuth } from "../contexts/AuthContext";
import ThemeToggleButton from "./themeToggleButton";

const Navbar = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const { isUserAuthenticated, deauthenticateUser } = useAuth();
  const isAuthenticatedUser = isUserAuthenticated();
  const navigate = useNavigate();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  };

  const handleNavBurgerMenuToggle = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeDropDownNavMenu = () => {
    setIsMenuActive(false);
  };

  const handleClickOutsideNavDropMenu = (e) => {
    const concernedElement1 = document.getElementById("navMenu");
    const concernedElement2 = document.getElementById("burger");

    if (
      !concernedElement1.contains(e.target) &&
      !concernedElement2.contains(e.target)
    ) {
      closeDropDownNavMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutsideNavDropMenu);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutsideNavDropMenu);
    };
  }, [handleClickOutsideNavDropMenu, handleScroll, prevScrollPos]);

  const handleLogout = () => {
    //deauthenticateUser();
    closeDropDownNavMenu();
    //navigate("/");
  };

  return (
    <div className="container">
      <nav
        className={`navbar ${
          visible ? "is-transparent is-fixed-top" : "is-fixed-top is-scrolling"
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <img src={vsnulogo} alt="" width="90" height="70" loading="lazy" />
          <Link to={"/"} className="navbar-item club-name-nav-link">
            <span className="clubName" style={{ fontSize: "1.2rem" }}>
              Varsinais-Suomen
              <br />
              Nepalin Urheiluyhdistys ry
            </span>
          </Link>

          <div
            className="navbar-item theme-toggle" /* style={{ paddinng: "0" }} */
          >
            <ThemeToggleButton />
          </div>

          <div
            role="button"
            className={`navbar-burger burger ${
              isMenuActive ? "is-active" : ""
            }`}
            id="burger"
            aria-label="menu"
            aria-expanded={isMenuActive ? "true" : "false"}
            data-target="navMenu"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "1rem",
            }}
            onClick={handleNavBurgerMenuToggle}
          >
            <span />
            <span />
            <span />
          </div>
        </div>

        <div
          className={`navbar-menu ${isMenuActive ? "is-active" : ""}`}
          id="navMenu"
        >
          <div className="navbar-end">
            <Link
              to={"/"}
              className="navbar-item"
              onClick={closeDropDownNavMenu}
            >
              Home
            </Link>
            <Link
              to={"/about"}
              className="navbar-item"
              onClick={closeDropDownNavMenu}
            >
              About
            </Link>
            {isAuthenticatedUser && (
              <Link
                to={"/Profile"}
                className="navbar-item"
                onClick={closeDropDownNavMenu}
              >
                Profile
              </Link>
            )}
            {isAuthenticatedUser && (
              <Link
                to={"/logout"}
                className="navbar-item"
                onClick={handleLogout}
              >
                Log Out
              </Link>
            )}
            {!isAuthenticatedUser && (
              <Link
                to={"/register"}
                className="navbar-item"
                onClick={closeDropDownNavMenu}
              >
                Register
              </Link>
            )}
            {!isAuthenticatedUser && (
              <Link
                to={"/login"}
                className="navbar-item"
                onClick={closeDropDownNavMenu}
              >
                Log in
              </Link>
            )}

            <div className="navbar-item has-dropdown is-hoverable navbar-menu">
              <a className="navbar-link">Event</a>

              <div className="navbar-dropdown">
                <Link
                  to={"/getTeams"}
                  className="navbar-item"
                  onClick={closeDropDownNavMenu}
                >
                  Registered Teams
                </Link>
                <Link
                  to={"/tiesheet"}
                  className="navbar-item"
                  onClick={closeDropDownNavMenu}
                >
                  Tie - Sheets
                </Link>
                <Link
                  to={"/livescore"}
                  className="navbar-item"
                  onClick={closeDropDownNavMenu}
                >
                  Live-Scores
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
