/* import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

const DynamicTables = () => {
  const [tables, setTables] = useState([]);
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [newTable, setNewTable] = useState({ header: "", selectedTeams: [] });

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teams = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setAllTeams(teams);
        setTeams(teams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  const handleAddTable = () => {
    setTables([
      ...tables,
      { header: newTable.header, teams: newTable.selectedTeams },
    ]);
    setNewTable({ header: "", selectedTeams: [] });
    updateAvailableTeams(newTable.selectedTeams.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable({ ...newTable, [name]: value });
  };

  const handleTeamChange = (selectedOptions) => {
    setNewTable({ ...newTable, selectedTeams: selectedOptions || [] });
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  return (
    <div className="container" style={{ height: "100vh", marginTop: "7rem" }}>
      <div className="field">
        <label className="label">Table Header</label>
        <div className="control">
          <input
            className="input"
            type="text"
            name="header"
            value={newTable.header}
            onChange={handleInputChange}
            placeholder="Enter table header"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Select Teams</label>
        <div className="control">
          <Select
            isMulti
            name="selectedTeams"
            options={teams}
            value={newTable.selectedTeams}
            onChange={handleTeamChange}
            placeholder="Select teams"
          />
        </div>
      </div>
      <button className="button is-primary" onClick={handleAddTable}>
        Add Table
      </button>

      <div className="mt-5">
        {tables.map((table, index) => (
          <div key={index} className="box">
            <h3 className="title is-4">{table.header}</h3>
            <ul>
              {table.teams.map((team, idx) => (
                <li key={idx}>{team.label}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicTables;
 */

/* import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

const DynamicTables = () => {
  const [tables, setTables] = useState([]);
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [newTable, setNewTable] = useState({ header: "", selectedTeams: [] });

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teams = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setAllTeams(teams);
        setTeams(teams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  const handleAddTable = () => {
    setTables([
      ...tables,
      { header: newTable.header, teams: newTable.selectedTeams },
    ]);
    setNewTable({ header: "", selectedTeams: [] });
    updateAvailableTeams(newTable.selectedTeams.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable({ ...newTable, [name]: value });
  };

  const handleTeamChange = (selectedOptions) => {
    setNewTable({ ...newTable, selectedTeams: selectedOptions || [] });
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    console.log(tables);
    try {
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}saveTables`, {
        tables,
      });
      alert("Tables saved successfully!");
    } catch (error) {
      console.error("Error saving tables:", error);
      alert("Failed to save tables.");
    }
  };

  return (
    <div
      className="container"
      style={{ height: "100vh", marginTop: "7rem", width: "100%" }}
    >
      <div className="field">
        <label className="label">Table Header</label>
        <div className="control">
          <input
            className="input"
            type="text"
            name="header"
            value={newTable.header}
            onChange={handleInputChange}
            placeholder="Enter table header"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Select Teams</label>
        <div className="control">
          <Select
            isMulti
            name="selectedTeams"
            options={teams}
            value={newTable.selectedTeams}
            onChange={handleTeamChange}
            placeholder="Select teams"
          />
        </div>
      </div>
      <button className="button is-primary" onClick={handleAddTable}>
        Add Table
      </button>
      <button className="button is-link mt-3" onClick={handleSaveTables}>
        Save Tables
      </button>

      <div className="mt-5">
        {tables.map((table, index) => (
          <div key={index} className="box">
            <h3 className="title is-4">{table.header}</h3>
            <ul>
              {table.teams.map((team, idx) => (
                <li key={idx}>{team.label}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicTables; */

// src/components/DynamicTables.js
/* import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

const DynamicTables = () => {
  const [tables, setTables] = useState([]); // Saved tables from the server
  const [newTables, setNewTables] = useState([]); // New tables to be added
  const [teams, setTeams] = useState([]); // Available teams for selection
  const [allTeams, setAllTeams] = useState([]); // All teams fetched from API
  const [newTable, setNewTable] = useState({ header: "", selectedTeams: [] });

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teams = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setAllTeams(teams);
        setTeams(teams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    // Fetch saved tables from the API
    const fetchTables = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}getTables`
        );
        setTables(response.data);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };
    fetchTables();
  }, []);

  const handleAddTable = () => {
    // Check if the header is provided and teams are selected
    if (!newTable.header || newTable.selectedTeams.length === 0) {
      alert("Please provide a header and select teams for the table.");
      return;
    }

    setNewTables((prevNewTables) => [
      ...prevNewTables,
      { header: newTable.header, teams: newTable.selectedTeams },
    ]);
    setNewTable({ header: "", selectedTeams: [] });
    updateAvailableTeams(newTable.selectedTeams.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevNewTable) => ({ ...prevNewTable, [name]: value }));
  };

  const handleTeamChange = (selectedOptions) => {
    setNewTable((prevNewTable) => ({
      ...prevNewTable,
      selectedTeams: selectedOptions || [],
    }));
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    console.log(newTables);
    if (newTables.length === 0) {
      alert("No new tables to save.");
      return;
    }
    try {
      // Post only the new tables to the server
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}saveTables`, {
        tables: newTables,
      });
      // Refresh saved tables
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}getTables`
      );
      setTables(response.data);
      setNewTables([]);
      alert("New tables saved successfully!");
    } catch (error) {
      console.error("Error saving tables:", error);
      alert("Failed to save new tables.");
    }
  };

  return (
    <div
      className="container"
      style={{ height: "100vh", marginTop: "7rem", width: "100%" }}
    >
      <div className="field">
        <label className="label">Table Header</label>
        <div className="control">
          <input
            className="input"
            type="text"
            name="header"
            value={newTable.header}
            onChange={handleInputChange}
            placeholder="Enter table header"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Select Teams</label>
        <div className="control">
          <Select
            isMulti
            name="selectedTeams"
            options={teams}
            value={newTable.selectedTeams}
            onChange={handleTeamChange}
            placeholder="Select teams"
          />
        </div>
      </div>
      <button className="button is-primary" onClick={handleAddTable}>
        Add Table
      </button>
      <button className="button is-link mt-3" onClick={handleSaveTables}>
        Save New Tables
      </button>

      <div className="mt-5">
        <h2 className="title is-4">Saved Tables</h2>
        {tables.length > 0 ? (
          tables.map((table, index) => (
            <div key={index} className="box">
              <h3 className="title is-4">{table.header}</h3>
              <ul>
                {table.teams.map((team, idx) => (
                  <li key={idx}>{team.label}</li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>No tables found.</p>
        )}
      </div>
    </div>
  );
};

export default DynamicTables; */

// src/components/DynamicTables.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import DynamicMatchTables from "./DynamicMatchTables";
import { useAuth } from "../../contexts/AuthContext";

const DynamicTables = () => {
  const [tables, setTables] = useState([]); // Saved tables from the server
  const [newTables, setNewTables] = useState([]); // New tables to be added
  const [teams, setTeams] = useState([]); // Available teams for selection
  const [allTeams, setAllTeams] = useState([]); // All teams fetched from API
  const [newTable, setNewTable] = useState({ header: "", selectedTeams: [] });

  const { isUserAuthenticated, isUserAdmin } = useAuth();

  useEffect(() => {
    // Fetch teams from JSONPlaceholder (using users as a substitute)
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}allTeams`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const teams = response.data.teams.map((allTeams) => ({
            value: allTeams.teamName,
            label: allTeams.teamName,
          }));
          /* const teams = response.data.teams
            .map((allTeams) =>
              allTeams.map((team) => ({
                value: team.team.name,
                label: team.team.name,
              }))
            )
            .flat(); */

          setAllTeams(teams);
          setTeams(teams);
        } else {
          throw new Error("Failed to fetch teams");
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    // Fetch saved tables from the API
    const fetchTables = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}getTables`
        );
        setTables(response.data);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };
    fetchTables();
  }, []);

  const handleAddTable = () => {
    if (!newTable.header || newTable.selectedTeams.length === 0) {
      toast.error("Please provide a header and select teams for the table.", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }

    // Add the new table to the newTables state
    setNewTables((prevNewTables) => [
      ...prevNewTables,
      { header: newTable.header, teams: newTable.selectedTeams },
    ]);

    // Add the new table to the UI immediately
    setTables((prevTables) => [
      ...prevTables,
      { header: newTable.header, teams: newTable.selectedTeams },
    ]);

    // Clear the new table form
    setNewTable({ header: "", selectedTeams: [] });
    updateAvailableTeams(newTable.selectedTeams.map((team) => team.value));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevNewTable) => ({ ...prevNewTable, [name]: value }));
  };

  const handleTeamChange = (selectedOptions) => {
    setNewTable((prevNewTable) => ({
      ...prevNewTable,
      selectedTeams: selectedOptions || [],
    }));
  };

  const updateAvailableTeams = (selectedTeams) => {
    setTeams(allTeams.filter((team) => !selectedTeams.includes(team.value)));
  };

  const handleSaveTables = async () => {
    if (newTables.length === 0) {
      toast.error("No new tables to save.", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }
    const token = localStorage.getItem("usertoken");
    const userToken = JSON.parse(token);
    try {
      // Post only the new tables to the server
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}saveTables`,
        {
          tables: newTables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        }
      );
      setNewTables([]); // Clear the newTables state after saving
      toast.success("New tables saved successfully!", {
        position: "top-center",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error saving tables:", error);
      toast.error("Failed to save new tables.", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  return (
    <div
      className="section tiesheet-section"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        marginTop: "2rem",
        width: "100%",
        overflow: "scroll",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isUserAuthenticated() && isUserAdmin() && (
        <div
          className="fixture-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            justifyContent: "center",
          }}
        >
          <div className="field">
            <label className="label">Table Header</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="header"
                value={newTable.header}
                onChange={handleInputChange}
                placeholder="Enter Group Name"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Select Teams</label>
            <div className="control">
              <Select
                isMulti
                name="selectedTeams"
                options={teams}
                value={newTable.selectedTeams}
                onChange={handleTeamChange}
                placeholder="Select teams"
              />
            </div>
          </div>
          <button
            className="button is-primary"
            onClick={handleAddTable}
            style={{ marginBottom: "0.5rem" }}
          >
            Add Table
          </button>
          <button className="button is-link mt-3" onClick={handleSaveTables}>
            Save New Tables
          </button>
        </div>
      )}

      <h2
        className="title is-4 fixtures-title"
        style={{
          textAlign: "center",
          marginTop: "2rem",
          textDecoration: "underline",
        }}
      >
        Group Fixtures
      </h2>
      <div
        className="mt-5 group-fixtures"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          marginBottom: "2rem",
        }}
      >
        {tables.length > 0 ? (
          tables.map((table, index) => (
            <div key={index} className="box" style={{ minwidth: "20%" }}>
              <h3 className="title is-4">{table.header}</h3>
              <ul>
                {table.teams.map((team, idx) => (
                  <li key={idx}>{team.label}</li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>No tables found.</p>
        )}
      </div>
      <DynamicMatchTables allRegisteredTeams={teams} />
    </div>
  );
};

export default DynamicTables;
