import React, { createContext, useState, useEffect, useContext } from "react";
import jwtDecode from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const isTokenExpired = (token) => {
    if (token) {
      const decoded = jwtDecode(token);
      return decoded.exp < Date.now() / 1000;
    }
    return true;
  };

  useEffect(() => {
    const token = localStorage.getItem("usertoken");
    if (token && !isTokenExpired(token)) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);

  const saveUserData = (token, user) => {
    localStorage.setItem("usertoken", token);
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const deauthenticateUser = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("user");
    setUser(null);
  };

  const isUserAuthenticated = () => {
    const token = localStorage.getItem("usertoken");
    return token && token.length > 10 && !isTokenExpired(token);
  };

  const checkRole = () => {
    if (user) {
      const role = user.role;
      return role === "superAdmin" || role === "admin";
    }
    return false;
  };

  const isUserAdmin = () => {
    // Get the token from local storage
    const token = localStorage.getItem("usertoken");

    if (!token) {
      return false; // No token found
    }

    try {
      // Decode the token
      const decodedToken = jwtDecode(token);
      // Check the user's role (assuming the role is stored in 'role' claim)
      return (
        (decodedToken.data.role &&
          decodedToken.data.role.toLowerCase() === "admin") ||
        "superAdmin"
      );
    } catch (error) {
      console.error("Error decoding token:", error);
      return false; // Token is invalid or decoding failed
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        saveUserData,
        deauthenticateUser,
        isUserAuthenticated,
        checkRole,
        isUserAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
