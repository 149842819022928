import React from "react";
import vsnulogo from "../assests/images/vsnulogo.jpeg";

const Footer = () => {
  return (
    <footer className="footer is-light">
      {/* <div className="content has-text-centered">
        <p>
          <strong>
            {"COPYRIGHT @FC GURKHA " +
              new Date().getFullYear() +
              ", Turku, Finland"}
          </strong>
        </p>
      </div> */}
      <div className="footercolumn" style={{ width: "100%", display: "flex" }}>
        <div className="columnone">
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <img
              src={vsnulogo}
              alt=""
              width="90"
              height="7"
              style={{ marginRight: "1rem" }}
            />
            <span style={{ fontSize: "1.2rem", textDecoration: "underline" }}>
              <b>
                Varsinais-Suomen<br></br>Nepalin Urheiluyhdistys ry
              </b>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            className="footerBusinessDetails"
          >
            <h3>
              <b>Y-tunnus: 3295549-3</b>
            </h3>
            <h3>
              <b>Yritysmuoto: Aatteellinen yhtiö (AYH / RY)</b>
            </h3>
          </div>
        </div>

        <div
          className="columns is-centered"
          style={{ margin: 0, textDecoration: "underline" }}
        >
          <div className="column">
            <span style={{ fontSize: "1.2rem" }}>
              <b>Follow Us</b>
            </span>
            <div className="social-media-links">
              <a
                href="https://www.facebook.com/profile.php?id=100070157026756"
                target="_blank"
                rel="noopener noreferrer"
                className="button is-link"
                style={{ marginBottom: "1rem", marginTop: "0.5rem" }}
              >
                <span className="icon">
                  <i className="fab fa-facebook"></i>
                </span>
                <span>Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/fcgurkha_vsnu/?utm_source=ig_web_button_share_sheet"
                target="_blank"
                rel="noopener noreferrer"
                className="button is-link instaLink"
              >
                <span className="icon">
                  <i className="fab fa-instagram"></i>
                </span>
                <span>Instagram</span>
              </a>
            </div>
          </div>
        </div>

        <div className="columntwo">
          <div
            style={{
              display: "inline-flex",
              alignItems: "flext-start",
              marginBottom: "1rem",
            }}
          >
            <span
              style={{
                fontSize: "1.2rem",
                marginRight: "262px",
                textDecoration: "underline",
              }}
              className="footerContact"
            >
              <b>Contact</b>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            className="footerContactDetails"
          >
            <h3>
              <b>Phone Number: +358 447859309</b>
            </h3>
            <h3>
              <b>Email: vsnuturku@gmail.com</b>
            </h3>
            <h3>
              <b>Postal Address: Frälsintie 9 2, 21200 RAISIO</b>
            </h3>
          </div>
        </div>
      </div>
      <div
        style={{ height: "auto", paddingTop: "3rem", paddingBottom: "3rem" }}
      >
        Copyright © 2024, Varsinais-Suomen Nepalin Urheiluyhdistys ry. All
        Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
