import React from "react";

const NimenhuutoEventWidget = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="eventWidget"
        style={{
          width: "30%",
          textAlign: "center",
          backgroundColor: "#fff",
          padding: "8px",
          margin: "5px 3px",
          border: "1px solid #00AAA9",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <a
            href="https://varsinais-suomennepalin.nimenhuuto.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "16px", textDecoration: "none", color: "#444" }}
          >
            <strong>Varsinais-Suomen Nepalin Event List</strong>
          </a>
          <div>
            »{" "}
            <a
              style={{ color: "#444", fontSize: "12px" }}
              href="https://varsinais-suomennepalin.nimenhuuto.com/calendar/monthly"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Calendar</strong>
            </a>{" "}
            ·{" "}
            <a
              style={{ color: "#444", fontSize: "12px" }}
              href="https://varsinais-suomennepalin.nimenhuuto.com/player"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Responses</strong>
            </a>
          </div>
        </div>
        <iframe
          style={{
            width: "100%",
            height: "450px",
            border: "none",
            padding: "0px",
            margin: "5px 0px 10px 0px",
            allowtransparency: "true",
          }}
          frameBorder="0"
          src="https://varsinais-suomennepalin.nimenhuuto.com/calendar/widget_iframe_events?css=&height=200&width=226"
          scrolling="auto"
          loading="lazy"
          title="Varsinais-Suomen Nepalin Event List"
        ></iframe>
      </div>
    </div>
  );
};

export default NimenhuutoEventWidget;
