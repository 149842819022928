/* import React, { Component } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export default class updatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      newPassword: "",
      isDone: false,
    };
  }

  updateToken = (e) => {
    this.setState({ token: e.target.value });
  };
  updatePassword = (e) => {
    this.setState({ newPassword: e.target.value });
  };

  onUpdatePassword = (e) => {
    e.preventDefault();
    const doc = {
      password: this.state.newPassword,
      passwordResetToken: this.state.token,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}updatePassword`, doc)
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ isDone: true });
          toast.success(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        } else if (res.data.success === false) {
          toast.error(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        } else {
          toast.error(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        }
      });

    this.setState({
      newPassword: "",
      token: "",
    });
  };
  render() {
    if (this.state.isDone === true) {
      return <Navigate to="/login" />;
    }

    return (
      <div className="login">
        <section className="section">
          <div className="form">
            <label className="label">Token</label>
            <input
              className="input"
              type="text"
              placeholder="Password Reset Token"
              value={this.state.token}
              onChange={this.updateToken}
            />
            <label className="label">New Password</label>
            <input
              className="input"
              type="password"
              placeholder="New Password"
              value={this.state.newPassword}
              onChange={this.updatePassword}
            />
            <br />
            <button
              className="button is-medium is-primary is-fullwidth"
              type="submit"
              onClick={this.onUpdatePassword}
            >
              Update Password
            </button>
          </div>
        </section>
      </div>
    );
  }
}
 */

import React, { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdatePassword = () => {
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isDone, setIsDone] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  // Handler for token input change
  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  // Handler for new password input change
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  // Function to handle password update
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (!token || !newPassword) {
      setIsSubmitting(false);
      toast.error("Token and new password are required.", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}updatePassword`,
        {
          password: newPassword,
          passwordResetToken: token,
        }
      );

      if (response.data.success) {
        setIsDone(true);
        toast.success(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setError("An error occurred while updating the password.");
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
        theme: "colored",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Redirect to login if the password update is successful
  if (isDone) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="login">
      <section className="section">
        <div className="form">
          <label className="label">Token</label>
          <input
            className="input"
            type="text"
            placeholder="Password Reset Token"
            value={token}
            onChange={handleTokenChange}
            disabled={isSubmitting}
          />
          <label className="label">New Password</label>
          <input
            className="input"
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={handlePasswordChange}
            disabled={isSubmitting}
          />
          <br />
          <button
            className="button is-medium is-primary is-fullwidth"
            type="submit"
            onClick={handleUpdatePassword}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Update Password"}
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      </section>
    </div>
  );
};

export default UpdatePassword;
