import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../components/home";
import Profile from "../components/profile";
import Register from "../components/register";
import Login from "../components/login";
import Logout from "../components/logout";
import Admin from "../components/admin/admin";
import Events from "../components/events";
import RegisterTeam from "../components/registerTeam";
import MyEvents from "../components/myEvents";
import UpdateEvent from "../components/updateEvent";
import About from "../components/about";
import GetTeams from "../components/getTeams";
import ForgotPassword from "../components/forgotPassword";
import UpdatePassword from "../components/updatePassword";
//import TeamMaker from "../components/teamMaker";
import DynamicTables from "../components/admin/DynamicTables";
import SoccerAdminTool from "../components/admin/LiveScoreTool";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact strict element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/updatePassword" element={<UpdatePassword />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/getTeams" element={<GetTeams />} />
      <Route path="/events" element={<Events />} />
      <Route path="/registerTeam/:id" element={<RegisterTeam />} />
      <Route path="/myEvents/:id" element={<MyEvents />} />
      <Route path="/updateEvent/:id" element={<UpdateEvent />} />
      <Route path="/about" element={<About />} />
      <Route path="tiesheet" element={<DynamicTables />} />
      <Route path="/livescore" element={<SoccerAdminTool />} />
      {/* <Route path="/manage" element={<TeamMaker />} /> */}
    </Routes>
  );
};

export default MyRoutes;
