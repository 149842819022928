/* import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

const SoccerAdminTool = () => {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newGame, setNewGame] = useState({
    groupNumber: "",
    team1: "",
    team2: "",
    team1Score: 0,
    team2Score: 0,
    startTime: "",
    breakTime: "",
    endTime: "",
    team1Scorers: [],
    team2Scorers: [],
    isLive: true,
  });

  useEffect(() => {
    // Fetch teams and players from mock API
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teamsData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const playersData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setPlayers(playersData);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchTeams();
    fetchPlayers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGame((prevGame) => ({ ...prevGame, [name]: value }));
  };

  const handleScoreChange = (team, score) => {
    setNewGame((prevGame) => ({ ...prevGame, [`${team}Score`]: score }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setNewGame((prevGame) => ({
      ...prevGame,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleMultiSelectChange = (name, selectedOptions) => {
    setNewGame((prevGame) => ({ ...prevGame, [name]: selectedOptions || [] }));
  };

  const handleAddGame = () => {
    if (!newGame.groupNumber || !newGame.team1 || !newGame.team2) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (editIndex !== null) {
      const updatedGames = [...games];
      updatedGames[editIndex] = newGame;
      setGames(updatedGames);
      setEditIndex(null);
    } else {
      setGames((prevGames) => [...prevGames, newGame]);
    }

    // Clear the form fields
    setNewGame({
      groupNumber: "",
      team1: "",
      team2: "",
      team1Score: 0,
      team2Score: 0,
      startTime: "",
      breakTime: "",
      endTime: "",
      team1Scorers: [],
      team2Scorers: [],
      isLive: true,
    });
  };

  const handleEditGame = (index) => {
    setNewGame(games[index]);
    setEditIndex(index);
  };

  const handleSaveGames = async () => {
    try {
      if (games.length === 0) {
        toast.error("No games to save.");
        return;
      }

      // Mock API call to save games
      await axios.post("https://jsonplaceholder.typicode.com/posts", { games });
      toast.success("Games saved successfully!");
    } catch (error) {
      console.error("Error saving games:", error);
      toast.error("Failed to save games.");
    }
  };

  const handleBreakTime = (index) => {
    const updatedGames = [...games];
    updatedGames[index] = {
      ...updatedGames[index],
      breakTime: new Date().toISOString().substr(11, 8), // Example format: HH:MM:SS
    };
    setGames(updatedGames);
  };

  const handleEndTime = (index) => {
    const updatedGames = [...games];
    updatedGames[index] = {
      ...updatedGames[index],
      endTime: new Date().toISOString().substr(11, 8), // Example format: HH:MM:SS
      isLive: false,
    };
    setGames(updatedGames);
  };

  return (
    <div className="container" style={{ marginTop: "7rem" }}>
      <div className="box">
        <h2 className="title is-4">
          {editIndex !== null ? "Edit Game" : "Add New Soccer Game"}
        </h2>
        <div className="field">
          <label className="label">Group Number</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="groupNumber"
              value={newGame.groupNumber}
              onChange={handleInputChange}
              placeholder="Enter group number"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1</label>
          <div className="control">
            <Select
              options={teams}
              onChange={(selectedOption) =>
                handleSelectChange("team1", selectedOption)
              }
              value={teams.find((team) => team.value === newGame.team1)}
              placeholder="Select Team 1"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2</label>
          <div className="control">
            <Select
              options={teams}
              onChange={(selectedOption) =>
                handleSelectChange("team2", selectedOption)
              }
              value={teams.find((team) => team.value === newGame.team2)}
              placeholder="Select Team 2"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1 Score</label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={newGame.team1Score}
              onChange={(e) => handleScoreChange("team1", e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2 Score</label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={newGame.team2Score}
              onChange={(e) => handleScoreChange("team2", e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Start Time</label>
          <div className="control">
            <input
              className="input"
              type="time"
              name="startTime"
              value={newGame.startTime}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Break Time</label>
          <div className="control">
            <input
              className="input"
              type="time"
              name="breakTime"
              value={newGame.breakTime}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">End Time</label>
          <div className="control">
            <input
              className="input"
              type="time"
              name="endTime"
              value={newGame.endTime}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1 Scorers</label>
          <div className="control">
            <Select
              isMulti
              options={players}
              onChange={(selectedOptions) =>
                handleMultiSelectChange("team1Scorers", selectedOptions)
              }
              value={newGame.team1Scorers}
              placeholder="Select Team 1 Scorers"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2 Scorers</label>
          <div className="control">
            <Select
              isMulti
              options={players}
              onChange={(selectedOptions) =>
                handleMultiSelectChange("team2Scorers", selectedOptions)
              }
              value={newGame.team2Scorers}
              placeholder="Select Team 2 Scorers"
            />
          </div>
        </div>
        <button className="button is-primary" onClick={handleAddGame}>
          {editIndex !== null ? "Update Game" : "Add Game"}
        </button>
        <button className="button is-link mt-3" onClick={handleSaveGames}>
          Save Games
        </button>
      </div>

      <div className="mt-5">
        <h2 className="title is-4">Games List</h2>
        {games.length > 0 ? (
          <table className="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th>Group Number</th>
                <th>Team 1</th>
                <th>Team 1 Score</th>
                <th>Team 2</th>
                <th>Team 2 Score</th>
                <th>Start Time</th>
                <th>Break Time</th>
                <th>End Time</th>
                <th>Team 1 Scorers</th>
                <th>Team 2 Scorers</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {games.map((game, index) => (
                <tr key={index}>
                  <td>{game.groupNumber}</td>
                  <td>{game.team1}</td>
                  <td>{game.team1Score}</td>
                  <td>{game.team2}</td>
                  <td>{game.team2Score}</td>
                  <td>{game.startTime}</td>
                  <td>{game.breakTime}</td>
                  <td>{game.endTime}</td>
                  <td>
                    {game.team1Scorers.map((scorer) => scorer.value).join(", ")}
                  </td>
                  <td>
                    {game.team2Scorers.map((scorer) => scorer.value).join(", ")}
                  </td>
                  <td>
                    <span
                      className={`tag ${
                        game.isLive ? "is-success" : "is-light"
                      }`}
                      style={{
                        borderRadius: "50%",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    />
                    {game.isLive ? "Live" : "Ended"}
                  </td>
                  <td>
                    <button
                      className="button is-small is-warning"
                      onClick={() => handleEditGame(index)}
                    >
                      Edit
                    </button>
                    <button
                      className="button is-small is-info ml-2"
                      onClick={() => handleBreakTime(index)}
                    >
                      Set Break Time
                    </button>
                    <button
                      className="button is-small is-danger ml-2"
                      onClick={() => handleEndTime(index)}
                    >
                      Set End Time
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No games added yet.</p>
        )}
      </div>
    </div>
  );
};

export default SoccerAdminTool;
 */

/*import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import {
  db,
  ref as sref,
  set,
  update,
  onValue,
} from "../../../src/firebase/firebaseconfig"; // Adjust path as needed

const SoccerAdminTool = () => {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newGame, setNewGame] = useState({
    groupNumber: "",
    team1: "",
    team2: "",
    team1Score: 0,
    team2Score: 0,
    startTime: "",
    breakTime: "",
    endTime: "",
    team1Scorers: [],
    team2Scorers: [],
    isLive: false,
    startTimestamp: null,
    breakTimestamp: null,
    endTimestamp: null,
    gameStatus: "Not Started",
  });

  const intervals = useRef({}); // Ensure intervals is an empty object

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teamsData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const playersData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setPlayers(playersData);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchTeams();
    fetchPlayers();
    fetchGames(); // Fetch saved games from Firebase
  }, []);

  const fetchGames = async () => {
    const gamesRef = sref(db, "games");
    onValue(gamesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGames(Object.values(data));
      }
    });
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "00:00";
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return "00:00";
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "00:00";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGame((prevGame) => ({ ...prevGame, [name]: value }));
  };

  const handleScoreChange = (team, score) => {
    setNewGame((prevGame) => ({ ...prevGame, [`${team}Score`]: score }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setNewGame((prevGame) => ({
      ...prevGame,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleMultiSelectChange = (name, selectedOptions) => {
    setNewGame((prevGame) => ({ ...prevGame, [name]: selectedOptions || [] }));
  };

  const handleAddGame = () => {
    if (!newGame.groupNumber || !newGame.team1 || !newGame.team2) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (editIndex !== null) {
      const updatedGames = [...games];
      updatedGames[editIndex] = newGame;
      setGames(updatedGames);
      setEditIndex(null);
    } else {
      setGames((prevGames) => [...prevGames, newGame]);
    }

    // Clear the form fields
    setNewGame({
      groupNumber: "",
      team1: "",
      team2: "",
      team1Score: 0,
      team2Score: 0,
      startTime: "",
      breakTime: "",
      endTime: "",
      team1Scorers: [],
      team2Scorers: [],
      isLive: false,
      startTimestamp: null,
      breakTimestamp: null,
      endTimestamp: null,
      gameStatus: "Not Started",
    });
  };

  const handleEditGame = (index) => {
    setNewGame(games[index]);
    setEditIndex(index);
  };

  const handleSaveGames = async () => {
    try {
      if (games.length === 0) {
        toast.error("No games to save.");
        return;
      }

      // Save games to Firebase
      for (let i = 0; i < games.length; i++) {
        await set(sref(db, `games/${i}`), games[i]);
      }
      toast.success("Games saved successfully!");
    } catch (error) {
      console.error("Error saving games:", error);
      toast.error("Failed to save games.");
    }
  };

  const startTimer = (index) => {
    if (intervals.current[index]) {
      // Timer already running for this index
      return;
    }

    const startTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - startTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, gameTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const startBreakTimer = (index) => {
    if (intervals.current[index]) {
      // Timer already running for this index
      return;
    }

    const breakStartTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - breakStartTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, breakTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const startSecondHalfTimer = (index) => {
    if (intervals.current[index]) {
      // Timer already running for this index
      return;
    }

    const secondHalfStartTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - secondHalfStartTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, secondHalfTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const stopTimer = (index) => {
    if (intervals.current[index]) {
      clearInterval(intervals.current[index]);
      intervals.current[index] = null;
    }
  };

  const handleStartGame = async (index) => {
    if (index < 0 || index >= games.length) {
      console.error("Invalid game index");
      return;
    }

    const updatedGames = [...games];
    const startTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      isLive: true,
      startTimestamp: startTime,
      gameStatus: "1st Half",
    };
    setGames(updatedGames);

    // Save the start time to Firebase
    await update(sref(db, `games/${index}`), {
      startTimestamp: startTime,
      isLive: true,
      gameStatus: "1st Half",
    });

    // Start the game timer
    startTimer(index);
  };

  const handleBreakTime = async (index) => {
    if (index < 0 || index >= games.length) {
      console.error("Invalid game index");
      return;
    }

    stopTimer(index); // Stop the game timer

    const updatedGames = [...games];
    const breakTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      breakTimestamp: breakTime,
      gameStatus: "Halftime",
    };
    setGames(updatedGames);

    // Save the break time to Firebase
    await update(sref(db, `games/${index}`), {
      breakTimestamp: breakTime,
      gameStatus: "Halftime",
    });

    // Start the break timer
    startBreakTimer(index);
  };

  const handleStartSecondHalf = async (index) => {
    if (index < 0 || index >= games.length) {
      console.error("Invalid game index");
      return;
    }

    stopTimer(index); // Stop the break timer

    const updatedGames = [...games];
    updatedGames[index] = {
      ...updatedGames[index],
      gameStatus: "2nd Half",
    };
    setGames(updatedGames);

    // Save the start of the 2nd half to Firebase
    await update(sref(db, `games/${index}`), {
      gameStatus: "2nd Half",
    });

    // Start the second half timer
    startSecondHalfTimer(index);
  };

  const handleEndGame = async (index) => {
    if (index < 0 || index >= games.length) {
      console.error("Invalid game index");
      return;
    }

    stopTimer(index); // Stop the game timer

    const updatedGames = [...games];
    const endTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      endTimestamp: endTime,
      gameStatus: "Fulltime",
    };
    setGames(updatedGames);

    // Save the end time to Firebase
    await update(sref(db, `games/${index}`), {
      endTimestamp: endTime,
      gameStatus: "Fulltime",
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Not Started":
        return "gray";
      case "1st Half":
      case "Halftime":
      case "2nd Half":
        return "green";
      case "Fulltime":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <div className="container">
      <h1 className="title">Soccer Admin Tool</h1>
      <div className="box">
        <h2 className="title is-4">Add/Edit Game</h2>
        <div className="field">
          <label className="label">Group Number</label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="groupNumber"
              value={newGame.groupNumber}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1</label>
          <div className="control">
            <Select
              options={teams}
              value={teams.find((team) => team.value === newGame.team1)}
              onChange={(option) => handleSelectChange("team1", option)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2</label>
          <div className="control">
            <Select
              options={teams}
              value={teams.find((team) => team.value === newGame.team2)}
              onChange={(option) => handleSelectChange("team2", option)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1 Score</label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={newGame.team1Score}
              onChange={(e) => handleScoreChange("team1", e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2 Score</label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={newGame.team2Score}
              onChange={(e) => handleScoreChange("team2", e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1 Scorers</label>
          <div className="control">
            <Select
              isMulti
              options={players}
              value={newGame.team1Scorers.map((scorer) => ({
                value: scorer,
                label: scorer,
              }))}
              onChange={(options) =>
                handleMultiSelectChange(
                  "team1Scorers",
                  options.map((option) => option.value)
                )
              }
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2 Scorers</label>
          <div className="control">
            <Select
              isMulti
              options={players}
              value={newGame.team2Scorers.map((scorer) => ({
                value: scorer,
                label: scorer,
              }))}
              onChange={(options) =>
                handleMultiSelectChange(
                  "team2Scorers",
                  options.map((option) => option.value)
                )
              }
            />
          </div>
        </div>
        <div className="field">
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-primary" onClick={handleAddGame}>
                {editIndex !== null ? "Update Game" : "Add Game"}
              </button>
            </div>
            <div className="control">
              <button className="button is-link" onClick={handleSaveGames}>
                Save All Games
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <h2 className="title is-4">Live Games</h2>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Group Number</th>
              <th>Team 1</th>
              <th>Team 2</th>
              <th>Score</th>
              <th>Start Time</th>
              <th>Break Time</th>
              <th>End Time</th>
              <th>Game Time</th>
              <th>Game Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {games?.map((game, index) => (
              <tr key={index}>
                <td>{game.groupNumber}</td>
                <td>
                  <div>{game.team1}</div>
                  <div>Scorers: {game.team1Scorers?.join(", ")}</div>
                </td>
                <td>
                  <div>{game.team2}</div>
                  <div>Scorers: {game.team2Scorers?.join(", ")}</div>
                </td>
                <td>{`${game.team1Score} - ${game.team2Score}`}</td>
                <td>{formatTime(game.startTimestamp)}</td>
                <td>{formatTime(game.breakTimestamp)}</td>
                <td>{formatTime(game.endTimestamp)}</td>
                <td>{game.gameTime || "00:00:00"}</td>
                <td>
                  <span
                    style={{
                      color: getStatusColor(game.gameStatus),
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    ●
                  </span>
                  {game.gameStatus}
                </td>
                <td>
                  {game.gameStatus === "Not Started" && (
                    <button
                      className="button is-primary is-small"
                      onClick={() => handleStartGame(index)}
                    >
                      Start Game
                    </button>
                  )}
                  {game.gameStatus === "1st Half" && (
                    <button
                      className="button is-link is-small"
                      onClick={() => handleBreakTime(index)}
                    >
                      Break Time
                    </button>
                  )}
                  {game.gameStatus === "Halftime" && (
                    <button
                      className="button is-link is-small"
                      onClick={() => handleStartSecondHalf(index)}
                    >
                      Start 2nd Half
                    </button>
                  )}
                  {game.gameStatus === "2nd Half" && (
                    <button
                      className="button is-danger is-small"
                      onClick={() => handleEndGame(index)}
                    >
                      End Game
                    </button>
                  )}
                  {game.gameStatus !== "Not Started" && (
                    <button
                      className="button is-info is-small"
                      onClick={() => handleEditGame(index)}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SoccerAdminTool;*/

/* import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import {
  db,
  ref as sref,
  set,
  update,
  onValue,
} from "../../../src/firebase/firebaseconfig"; // Adjust path as needed

const SoccerAdminTool = () => {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newGame, setNewGame] = useState({
    groupNumber: "",
    team1: "",
    team2: "",
    team1Score: 0,
    team2Score: 0,
    team1Scorers: [],
    team2Scorers: [],
    isLive: false,
    startTimestamp: null,
    breakTimestamp: null,
    endTimestamp: null,
    gameStatus: "Not Started",
    gameTime: "00:00:00",
    breakTime: "00:00:00",
    secondHalfTime: "00:00:00",
  });

  const intervals = useRef({});

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teamsData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const playersData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setPlayers(playersData);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchTeams();
    fetchPlayers();
    fetchGames();
  }, []);

  const fetchGames = async () => {
    const gamesRef = sref(db, "games");
    onValue(gamesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGames(Object.values(data));
      }
    });
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "00:00:00";
    try {
      const date = new Date(timestamp);
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "00:00:00";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGame((prevGame) => ({ ...prevGame, [name]: value }));
  };

  const handleScoreChange = (team, score) => {
    setNewGame((prevGame) => ({ ...prevGame, [`${team}Score`]: score }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setNewGame((prevGame) => ({
      ...prevGame,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleMultiSelectChange = (name, selectedOptions) => {
    setNewGame((prevGame) => ({ ...prevGame, [name]: selectedOptions || [] }));
  };

  const handleAddGame = () => {
    if (!newGame.groupNumber || !newGame.team1 || !newGame.team2) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (editIndex !== null) {
      const updatedGames = [...games];
      updatedGames[editIndex] = newGame;
      setGames(updatedGames);
      setEditIndex(null);
    } else {
      setGames((prevGames) => [...prevGames, newGame]);
    }

    // Clear the form fields
    setNewGame({
      groupNumber: "",
      team1: "",
      team2: "",
      team1Score: 0,
      team2Score: 0,
      team1Scorers: [],
      team2Scorers: [],
      isLive: false,
      startTimestamp: null,
      breakTimestamp: null,
      endTimestamp: null,
      gameStatus: "Not Started",
      gameTime: "00:00:00",
      breakTime: "00:00:00",
      secondHalfTime: "00:00:00",
    });
  };

  const handleEditGame = (index) => {
    setNewGame(games[index]);
    setEditIndex(index);
  };

  const handleSaveGames = async () => {
    try {
      if (games.length === 0) {
        toast.error("No games to save.");
        return;
      }

      // Save games to Firebase
      for (let i = 0; i < games.length; i++) {
        await set(sref(db, `games/${i}`), games[i]);
      }
      toast.success("Games saved successfully!");
    } catch (error) {
      console.error("Error saving games:", error);
      toast.error("Failed to save games.");
    }
  };

  const startTimer = (index) => {
    if (intervals.current[index]) return;

    const startTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - startTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, gameTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const startBreakTimer = (index) => {
    if (intervals.current[index]) return;

    const breakStartTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - breakStartTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, breakTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const startSecondHalfTimer = (index) => {
    if (intervals.current[index]) return;

    const secondHalfStartTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - secondHalfStartTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, secondHalfTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const stopTimer = (index) => {
    if (intervals.current[index]) {
      clearInterval(intervals.current[index]);
      intervals.current[index] = null;
    }
  };

  const handleStartGame = async (index) => {
    if (index < 0 || index >= games.length) return;

    const updatedGames = [...games];
    const startTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      isLive: true,
      startTimestamp: startTime,
      gameStatus: "1st Half",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      startTimestamp: startTime,
      isLive: true,
      gameStatus: "1st Half",
    });

    startTimer(index);
  };

  const handleBreakTime = async (index) => {
    if (index < 0 || index >= games.length) return;

    stopTimer(index);

    const updatedGames = [...games];
    const breakTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      breakTimestamp: breakTime,
      gameStatus: "Halftime",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      breakTimestamp: breakTime,
      gameStatus: "Halftime",
    });

    startBreakTimer(index);
  };

  const handleSecondHalfStart = async (index) => {
    if (index < 0 || index >= games.length) return;

    stopTimer(index);

    const updatedGames = [...games];
    const secondHalfStartTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      secondHalfTime: secondHalfStartTime,
      gameStatus: "2nd Half",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      secondHalfTime: secondHalfStartTime,
      gameStatus: "2nd Half",
    });

    startSecondHalfTimer(index);
  };

  const handleEndGame = async (index) => {
    if (index < 0 || index >= games.length) return;

    stopTimer(index);

    const updatedGames = [...games];
    const endTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      endTimestamp: endTime,
      gameStatus: "Finished",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      endTimestamp: endTime,
      gameStatus: "Finished",
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "1st Half":
        return "is-primary";
      case "Halftime":
        return "is-warning";
      case "2nd Half":
        return "is-info";
      case "Finished":
        return "is-success";
      default:
        return "is-light";
    }
  };

  const formatScorers = (scorers) => {
    return scorers.length > 0
      ? `(${scorers.map((scorer) => scorer.label).join(", ")})`
      : "";
  };

  return (
    <div className="container">
      <h1 className="title has-text-centered">Soccer Admin Tool</h1>
      <div className="box">
        <h2 className="subtitle">New Game</h2>
        <div className="field">
          <label className="label">Group Number:</label>
          <div className="control">
            <input
              type="text"
              name="groupNumber"
              value={newGame.groupNumber}
              onChange={handleInputChange}
              className="input is-fullwidth"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1:</label>
          <div className="control">
            <Select
              name="team1"
              options={teams}
              onChange={(option) => handleSelectChange("team1", option)}
              value={teams.find((team) => team.value === newGame.team1) || null}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2:</label>
          <div className="control">
            <Select
              name="team2"
              options={teams}
              onChange={(option) => handleSelectChange("team2", option)}
              value={teams.find((team) => team.value === newGame.team2) || null}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1 Score:</label>
          <div className="control">
            <input
              type="number"
              value={newGame.team1Score}
              onChange={(e) =>
                handleScoreChange("team1", parseInt(e.target.value))
              }
              className="input is-fullwidth"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2 Score:</label>
          <div className="control">
            <input
              type="number"
              value={newGame.team2Score}
              onChange={(e) =>
                handleScoreChange("team2", parseInt(e.target.value))
              }
              className="input is-fullwidth"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 1 Scorers:</label>
          <div className="control">
            <Select
              isMulti
              options={players}
              onChange={(options) =>
                handleMultiSelectChange("team1Scorers", options)
              }
              value={newGame.team1Scorers}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Team 2 Scorers:</label>
          <div className="control">
            <Select
              isMulti
              options={players}
              onChange={(options) =>
                handleMultiSelectChange("team2Scorers", options)
              }
              value={newGame.team2Scorers}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
        </div>
        <div className="control">
          <button
            onClick={handleAddGame}
            className="button is-primary is-fullwidth"
          >
            {editIndex !== null ? "Update Game" : "Add Game"}
          </button>
        </div>
      </div>
      <div className="box">
        <h2 className="subtitle">Games List</h2>
        <div className="table-container">
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th>Group Number</th>
                <th>Team 1</th>
                <th>Team 2</th>
                <th>Score</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {games?.map((game, index) => (
                <tr key={index}>
                  <td>{game.groupNumber}</td>
                  <td>
                    <div>
                      {game.team1}
                      {game.team1Scorers?.length > 0 && (
                        <div className="has-text-info">
                          {formatScorers(game.team1Scorers)}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {game.team2}
                      {game.team2Scorers?.length > 0 && (
                        <div className="has-text-info">
                          {formatScorers(game.team2Scorers)}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    {game.team1Score} - {game.team2Score}
                  </td>
                  <td>
                    <span className={`tag ${getStatusColor(game.gameStatus)}`}>
                      {game.gameStatus}
                    </span>
                  </td>
                  <td>
                    <div className="buttons is-centered">
                      <button
                        onClick={() => handleEditGame(index)}
                        className="button is-warning is-small"
                        style={{ margin: "0.5rem" }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleStartGame(index)}
                        className="button is-primary is-small"
                        style={{ margin: "0.5rem" }}
                      >
                        Start
                      </button>
                      <button
                        onClick={() => handleBreakTime(index)}
                        className="button is-warning is-small"
                        style={{ margin: "0.5rem" }}
                      >
                        Break
                      </button>
                      <button
                        onClick={() => handleSecondHalfStart(index)}
                        className="button is-info is-small"
                        style={{ margin: "0.5rem" }}
                      >
                        2nd Half
                      </button>
                      <button
                        onClick={() => handleEndGame(index)}
                        className="button is-danger is-small"
                        style={{ margin: "0.5rem" }}
                      >
                        End
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="control mt-4">
          <button
            onClick={handleSaveGames}
            className="button is-primary is-fullwidth"
          >
            Save Games
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoccerAdminTool; */

import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import {
  db,
  ref as sref,
  set,
  update,
  onValue,
} from "../../../src/firebase/firebaseconfig"; // Adjust path as needed

const SoccerAdminTool = () => {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newGame, setNewGame] = useState({
    groupNumber: "",
    team1: "",
    team2: "",
    team1Score: 0,
    team2Score: 0,
    team1Scorers: [],
    team2Scorers: [],
    isLive: false,
    startTimestamp: null,
    breakTimestamp: null,
    endTimestamp: null,
    gameStatus: "Not Started",
    gameTime: "00:00:00",
    breakTime: "00:00:00",
    secondHalfTime: "00:00:00",
  });
  const { isUserAuthenticated, isUserAdmin } = useAuth();

  const intervals = useRef({});

  useEffect(() => {
    /* const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const teamsData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    }; */

    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}allTeams`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const teams = response.data.teams.map((allTeams) => ({
            value: allTeams.teamName,
            label: allTeams.teamName,
          }));

          setTeams(teams);
        } else {
          throw new Error("Failed to fetch teams");
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        const playersData = response.data.map((user) => ({
          value: user.name,
          label: user.name,
        }));
        //setPlayers(playersData);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchTeams();
    fetchPlayers();
    fetchGames();
  }, []);

  const fetchGames = async () => {
    const gamesRef = sref(db, "games");
    onValue(gamesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGames(Object.values(data));
      }
    });
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "00:00:00";
    try {
      const date = new Date(timestamp);
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "00:00:00";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGame((prevGame) => ({ ...prevGame, [name]: value }));
  };

  const handleScoreChange = (team, score) => {
    setNewGame((prevGame) => ({ ...prevGame, [`${team}Score`]: score }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setNewGame((prevGame) => ({
      ...prevGame,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleMultiSelectChange = (name, selectedOptions) => {
    setNewGame((prevGame) => ({ ...prevGame, [name]: selectedOptions || [] }));
  };

  const handleAddGame = () => {
    if (!newGame.groupNumber || !newGame.team1 || !newGame.team2) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (editIndex !== null) {
      const updatedGames = [...games];
      updatedGames[editIndex] = newGame;
      setGames(updatedGames);
      setEditIndex(null);
    } else {
      setGames((prevGames) => [...prevGames, newGame]);
    }

    // Clear the form fields
    setNewGame({
      groupNumber: "",
      team1: "",
      team2: "",
      team1Score: 0,
      team2Score: 0,
      team1Scorers: [],
      team2Scorers: [],
      isLive: false,
      startTimestamp: null,
      breakTimestamp: null,
      endTimestamp: null,
      gameStatus: "Not Started",
      gameTime: "00:00:00",
      breakTime: "00:00:00",
      secondHalfTime: "00:00:00",
    });
  };

  const handleEditGame = (index) => {
    setNewGame(games[index]);
    setEditIndex(index);
  };

  const handleSaveGames = async () => {
    try {
      if (games.length === 0) {
        toast.error("No games to save.");
        return;
      }

      // Save games to Firebase
      for (let i = 0; i < games.length; i++) {
        await set(sref(db, `games/${i}`), games[i]);
      }
      toast.success("Games saved successfully!");
    } catch (error) {
      console.error("Error saving games:", error);
      toast.error("Failed to save games.");
    }
  };

  const startTimer = (index) => {
    if (intervals.current[index]) return;

    const startTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - startTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, gameTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const startBreakTimer = (index) => {
    if (intervals.current[index]) return;

    const breakStartTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - breakStartTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, breakTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const startSecondHalfTimer = (index) => {
    if (intervals.current[index]) return;

    const secondHalfStartTime = new Date();
    intervals.current[index] = setInterval(() => {
      const elapsed = new Date() - secondHalfStartTime;
      const hours = String(Math.floor(elapsed / 3600000)).padStart(2, "0");
      const minutes = String(Math.floor((elapsed % 3600000) / 60000)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((elapsed % 60000) / 1000)).padStart(
        2,
        "0"
      );
      setGames((prevGames) =>
        prevGames.map((game, i) =>
          i === index
            ? { ...game, secondHalfTime: `${hours}:${minutes}:${seconds}` }
            : game
        )
      );
    }, 1000);
  };

  const stopTimer = (index) => {
    if (intervals.current[index]) {
      clearInterval(intervals.current[index]);
      intervals.current[index] = null;
    }
  };

  const handleStartGame = async (index) => {
    if (index < 0 || index >= games.length) return;

    const updatedGames = [...games];
    const startTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      isLive: true,
      startTimestamp: startTime,
      gameStatus: "1st Half",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      startTimestamp: startTime,
      isLive: true,
      gameStatus: "1st Half",
    });

    startTimer(index);
  };

  const handleBreakTime = async (index) => {
    if (index < 0 || index >= games.length) return;

    stopTimer(index);

    const updatedGames = [...games];
    const breakTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      breakTimestamp: breakTime,
      gameStatus: "Halftime",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      breakTimestamp: breakTime,
      gameStatus: "Halftime",
    });

    startBreakTimer(index);
  };

  const handleSecondHalfStart = async (index) => {
    if (index < 0 || index >= games.length) return;

    stopTimer(index);

    const updatedGames = [...games];
    const secondHalfStartTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      secondHalfTime: secondHalfStartTime,
      gameStatus: "2nd Half",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      secondHalfTime: secondHalfStartTime,
      gameStatus: "2nd Half",
    });

    startSecondHalfTimer(index);
  };

  const handleEndGame = async (index) => {
    if (index < 0 || index >= games.length) return;

    stopTimer(index);

    const updatedGames = [...games];
    const endTime = new Date().toISOString();
    updatedGames[index] = {
      ...updatedGames[index],
      endTimestamp: endTime,
      gameStatus: "FullTime",
    };
    setGames(updatedGames);

    await update(sref(db, `games/${index}`), {
      endTimestamp: endTime,
      gameStatus: "FullTime",
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "1st Half":
        return "is-primary";
      case "Halftime":
        return "is-warning";
      case "2nd Half":
        return "is-info";
      case "FullTime":
        return "is-success";
      default:
        return "is-light";
    }
  };

  const formatScorers = (scorers) => {
    return scorers.length > 0
      ? `${scorers.map((scorer) => scorer.label).join(", ")}`
      : "";
  };

  return (
    <div
      className="container livescore-container"
      /* style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        marginTop: "2rem",
        width: "100%",
        overflow: "scroll",
        justifyContent: "center",
        alignItems: "center",
      }} */
    >
      {isUserAuthenticated() && isUserAdmin() && (
        <>
          <h1 className="title has-text-centered">Soccer Admin Tool</h1>
          <div className="box">
            <h2 className="subtitle">New Game</h2>
            <div className="field">
              <label className="label">Group Number:</label>
              <div className="control">
                <input
                  type="text"
                  name="groupNumber"
                  value={newGame.groupNumber}
                  onChange={handleInputChange}
                  className="input is-fullwidth"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Team 1:</label>
              <div className="control">
                <Select
                  name="team1"
                  options={teams}
                  onChange={(option) => handleSelectChange("team1", option)}
                  value={
                    teams.find((team) => team.value === newGame.team1) || null
                  }
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Team 2:</label>
              <div className="control">
                <Select
                  name="team2"
                  options={teams}
                  onChange={(option) => handleSelectChange("team2", option)}
                  value={
                    teams.find((team) => team.value === newGame.team2) || null
                  }
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Team 1 Score:</label>
              <div className="control">
                <input
                  type="number"
                  value={newGame.team1Score}
                  onChange={(e) =>
                    handleScoreChange("team1", parseInt(e.target.value))
                  }
                  className="input is-fullwidth"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Team 2 Score:</label>
              <div className="control">
                <input
                  type="number"
                  value={newGame.team2Score}
                  onChange={(e) =>
                    handleScoreChange("team2", parseInt(e.target.value))
                  }
                  className="input is-fullwidth"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Team 1 Scorers:</label>
              <div className="control">
                <Creatable
                  isMulti
                  options={players}
                  onChange={(options) =>
                    handleMultiSelectChange("team1Scorers", options)
                  }
                  value={newGame.team1Scorers}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Team 2 Scorers:</label>
              <div className="control">
                <Creatable
                  isMulti
                  options={players}
                  onChange={(options) =>
                    handleMultiSelectChange("team2Scorers", options)
                  }
                  value={newGame.team2Scorers}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              </div>
            </div>
            <div className="control">
              <button
                onClick={handleAddGame}
                className="button is-primary is-fullwidth"
              >
                {editIndex !== null ? "Update Game" : "Add Game"}
              </button>
            </div>
          </div>
        </>
      )}

      <div className="box">
        <h2
          className="subtitle"
          style={{ textAlign: "center", textDecoration: "underline" }}
        >
          <strongn>Live Scores</strongn>
        </h2>
        <div className="table-container">
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th>Group Number</th>
                <th>Team 1</th>
                <th>Team 2</th>
                <th>Score</th>
                <th style={{ textAlign: "center" }}>Status</th>
                {isUserAuthenticated() && isUserAdmin() && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {games?.map((game, index) => (
                <tr key={index}>
                  <td>{game.groupNumber}</td>
                  <td>
                    <div>
                      {game.team1}
                      {game.team1Scorers?.length > 0 && (
                        <div className="has-text-info">
                          {formatScorers(game.team1Scorers)}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {game.team2}
                      {game.team2Scorers?.length > 0 && (
                        <div className="has-text-info">
                          {formatScorers(game.team2Scorers)}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    {game.team1Score} - {game.team2Score}
                  </td>
                  <td>
                    <span className={`tag ${getStatusColor(game.gameStatus)}`}>
                      {game.gameStatus}
                    </span>
                  </td>
                  {isUserAuthenticated() && isUserAdmin() && (
                    <td>
                      <div className="buttons is-centered">
                        <button
                          onClick={() => handleEditGame(index)}
                          className="button is-warning is-small"
                          style={{ margin: "0.5rem" }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleStartGame(index)}
                          className="button is-primary is-small"
                          style={{ margin: "0.5rem" }}
                        >
                          Start
                        </button>
                        <button
                          onClick={() => handleBreakTime(index)}
                          className="button is-warning is-small"
                          style={{ margin: "0.5rem" }}
                        >
                          Break
                        </button>
                        <button
                          onClick={() => handleSecondHalfStart(index)}
                          className="button is-info is-small"
                          style={{ margin: "0.5rem" }}
                        >
                          2nd Half
                        </button>
                        <button
                          onClick={() => handleEndGame(index)}
                          className="button is-danger is-small"
                          style={{ margin: "0.5rem" }}
                        >
                          End
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isUserAuthenticated() && isUserAdmin() && (
          <div className="control mt-4">
            <button
              onClick={handleSaveGames}
              className="button is-primary is-fullwidth"
            >
              Save Games
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoccerAdminTool;
