// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, update, onValue } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBYuySGIITCOOmjjCIu_IjZ96zNVcVOkc4",
  authDomain: "vsnu-1069e.firebaseapp.com",
  databaseURL:
    "https://vsnu-1069e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vsnu-1069e",
  storageBucket: "vsnu-1069e.appspot.com",
  messagingSenderId: "283110860838",
  appId: "1:283110860838:web:003ca06e9e11e346bc9ee7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export { db, ref, set, update, onValue };

//   import { db, ref, set, update, onValue } from "../../../src/firebase/firebaseconfig";
