/* import React, { Component } from "react";
import axios from "axios";
import { Navigate, Link } from "react-router-dom";
import Loader from "./loader";
import Auth from "../auth/auth";

export default class myEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      myEvents: [],
    };
  }

  goback = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    const token = localStorage.getItem("usertoken");
    //const user = localStorage.getItem("user");
    if (token !== null) {
      const userToken = JSON.parse(token);
      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT}myBooking/` +
            this.props.match.params.id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            myEvents: res.data.singleBooking,
            isLoading: false,
          });
        });
    }
  }

  render() {
    if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
      return <Navigate to="/login" />;
    }
    const { myEvents, isLoading } = this.state;
    let loader = isLoading && <Loader />;
    let warningMessage = !isLoading && myEvents.length <= 0 && (
      <p id="warningMessage">"No items Found"</p>
    );
    return (
      <div className="myEvents">
        {loader}
        {warningMessage}

        <div className="tile is-ancestor">
          <div className="tile is-vertical is-8">
            {myEvents &&
              myEvents.map((myEvent, i) => (
                <div className="tile" key={i}>
                  <div className="tile is-parent is-vertical">
                    <article
                      className="tile is-child notification is-primary"
                      id="teamDetails"
                    >
                      <p className="subtitle" style={{ textAlign: "center" }}>
                        <strong>{i + 1 + ").  "}</strong>Team Details:
                      </p>
                      <p className="subtitle">
                        Team Name:
                        <strong>
                          <span className="tag is-info">
                            {myEvent.teamName}
                          </span>
                        </strong>
                      </p>
                      <p className="subtitle">
                        Manager Name:
                        <strong>
                          <span className="tag is-info">
                            {myEvent.managerName}
                          </span>
                        </strong>
                      </p>
                      <p className="subtitle">
                        Contact Number:
                        <strong>
                          <span className="tag is-info">
                            {myEvent.contactNumber}
                          </span>
                        </strong>
                      </p>
                    </article>
                    <article
                      className="tile is-child notification is-warning"
                      id="editEvent"
                    >
                      <button className="button is-danger">
                        <Link to={"/updateEvent/" + myEvent._id}>
                          Edit Your Event
                        </Link>
                      </button>
                    </article>
                  </div>
                  <div className="tile is-parent">
                    <article
                      className="tile is-child notification is-primary"
                      id="eventsDetailsList"
                    >
                      <div className="subtitle" id="players">
                        <p className="subtitle" style={{ textAlign: "center" }}>
                          Player's Name List:
                        </p>
                        <strong>
                          <ol>
                            {myEvent.playersName.map((myPlayer, p) => (
                              <li key={p} className="playersName">
                                <span className="tag is-info">{myPlayer}</span>
                              </li>
                            ))}
                          </ol>
                        </strong>
                      </div>
                    </article>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
 */

/* import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, Link, useParams, useNavigate } from "react-router-dom";
import Loader from "./loader";
import Auth from "../auth/auth";

const MyEvents = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myEvents, setMyEvents] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);
      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}myBooking/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          setMyEvents(res.data.singleBooking);
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);
      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}eventdetails/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          setEventDetails(res.data.singleEvent);
          setIsLoading(false);
        });
    }
  }, [id]);

  const goBack = () => {
    navigate("/profile");
  };

  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  let loader = isLoading && <Loader />;
  let warningMessage = !isLoading && myEvents.length <= 0 && (
    <p id="warningMessage">"No items Found"</p>
  );

  return (
    <div className="myEvents">
      {loader}
      {warningMessage}

      <div className="tile is-ancestor">
        <div className="tile is-vertical is-8">
          {myEvents &&
            myEvents.map((myEvent, i) => (
              <div className="tile" key={i}>
                <div className="tile is-parent is-vertical">
                  <article
                    className="tile is-child notification is-text"
                    id="teamDetails"
                  >
                    {eventDetails?.map(
                      (eventDetail, i) =>
                        eventDetail._id === myEvent.event && (
                          <p
                            className="subtitle"
                            style={{
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            key={i}
                          >
                            <strong>{eventDetail?.eventName}</strong>
                          </p>
                        )
                    )}
                    <p className="subtitle" style={{ textAlign: "center" }}>
                      <strong>{i + 1 + ").  "}</strong>Team Details:
                    </p>
                    <p className="subtitle">
                      Team Name:
                      <strong>
                        <span className="tag is-info">{myEvent.teamName}</span>
                      </strong>
                    </p>
                    <p className="subtitle">
                      Manager Name:
                      <strong>
                        <span className="tag is-info">
                          {myEvent.managerName}
                        </span>
                      </strong>
                    </p>
                    <p className="subtitle">
                      Contact Number:
                      <strong>
                        <span className="tag is-info">
                          {myEvent.contactNumber}
                        </span>
                      </strong>
                    </p>
                  </article>
                  <article
                    className="tile is-child notification is-text"
                    id="editEvent"
                  >
                    <button className="button is-info">
                      <Link to={`/updateEvent/${myEvent._id}`}>
                        Edit Your Event Details
                      </Link>
                    </button>
                  </article>
                </div>
                <div className="tile is-parent">
                  <article
                    className="tile is-child notification is-text"
                    id="eventsDetailsList"
                  >
                    <div className="subtitle" id="players">
                      <p className="subtitle" style={{ textAlign: "center" }}>
                        Player's Name List:
                      </p>
                      <strong>
                        <ol>
                          {myEvent?.playersName?.map((myPlayer, p) => (
                            <li key={p} className="playersName">
                              <span className="tag is-info">{myPlayer}</span>
                            </li>
                          ))}
                        </ol>
                      </strong>
                    </div>
                  </article>
                </div>
              </div>
            ))}
          <button
            className="button is-warning"
            onClick={goBack}
            style={{ margin: "0 0.75rem 0 0.75rem" }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyEvents; */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, Link, useParams, useNavigate } from "react-router-dom";
import Loader from "./loader";
import Auth from "../auth/auth";

const MyEvents = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myEvents, setMyEvents] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [error, setError] = useState(null); // Add state to handle errors
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("usertoken");

      if (!token) {
        setError("Authentication token not found.");
        setIsLoading(false);
        return;
      }

      const userToken = JSON.parse(token);

      try {
        const [eventsResponse, detailsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_ENDPOINT}myBooking/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_API_ENDPOINT}eventdetails/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }),
        ]);

        setMyEvents(eventsResponse.data.singleBooking || []);
        setEventDetails(detailsResponse.data.singleEvent || []);
      } catch (error) {
        if (error.response) {
          // Handle HTTP errors
          switch (error.response.status) {
            case 401:
              setError("Unauthorized. Please log in again.");
              break;
            case 404:
              setError("No Items Found. Please register to the event.");
              break;
            case 500:
              setError("Server Error. Please try again later.");
              break;
            default:
              setError("An unexpected error occurred.");
          }
        } else {
          // Handle network errors
          setError("Network Error. Please check your internet connection.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const goBack = () => {
    navigate("/profile");
  };

  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="myEvents">
      {isLoading && <Loader />}
      {error && <div className="notification is-danger">{error}</div>}

      {!isLoading && !error && myEvents.length <= 0 && (
        <p id="warningMessage">No items found.</p>
      )}

      {!isLoading && !error && myEvents.length > 0 && (
        <div className="tile is-ancestor">
          <div className="tile is-vertical is-8">
            {myEvents.map((myEvent, i) => (
              <div className="tile" key={i}>
                <div className="tile is-parent is-vertical">
                  <article
                    className="tile is-child notification is-text"
                    id="teamDetails"
                  >
                    {eventDetails.find(
                      (eventDetail) => eventDetail._id === myEvent.event
                    ) && (
                      <p
                        className="subtitle"
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                      >
                        <strong>
                          {
                            eventDetails.find(
                              (eventDetail) => eventDetail._id === myEvent.event
                            ).eventName
                          }
                        </strong>
                      </p>
                    )}
                    <p className="subtitle" style={{ textAlign: "center" }}>
                      <strong>{i + 1 + ").  "}</strong>Team Details:
                    </p>
                    <p className="subtitle">
                      Team Name:
                      <strong>
                        <span className="tag is-info">{myEvent.teamName}</span>
                      </strong>
                    </p>
                    <p className="subtitle">
                      Manager Name:
                      <strong>
                        <span className="tag is-info">
                          {myEvent.managerName}
                        </span>
                      </strong>
                    </p>
                    <p className="subtitle">
                      Contact Number:
                      <strong>
                        <span className="tag is-info">
                          {myEvent.contactNumber}
                        </span>
                      </strong>
                    </p>
                  </article>
                  <article
                    className="tile is-child notification is-text"
                    id="editEvent"
                  >
                    <button className="button is-info">
                      <Link to={`/updateEvent/${myEvent._id}`}>
                        Edit Your Event Details
                      </Link>
                    </button>
                  </article>
                </div>
                <div className="tile is-parent">
                  <article
                    className="tile is-child notification is-text"
                    id="eventsDetailsList"
                  >
                    <div className="subtitle" id="players">
                      <p className="subtitle" style={{ textAlign: "center" }}>
                        Player's Name List:
                      </p>
                      <strong>
                        <ol>
                          {myEvent?.playersName?.map((myPlayer, p) => (
                            <li key={p} className="playersName">
                              <span className="tag is-info">{myPlayer}</span>
                            </li>
                          ))}
                        </ol>
                      </strong>
                    </div>
                  </article>
                </div>
              </div>
            ))}
            <button
              className="button is-warning"
              onClick={goBack}
              style={{ margin: "0 0.75rem 0 0.75rem" }}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyEvents;
