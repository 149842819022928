import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/navbar";
import MyRoutes from "./routes/routes";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/footer";
import { useTheme } from "./contexts/themeContext";
import { Triangle } from "react-loader-spinner";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [loading, setLoading] = useState(true);
  const { isDarkMode } = useTheme();

  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const margin = isMobile ? "4.25rem 0 0 0" : "4.75rem 0 0 0";

  useEffect(() => {
    // Simulate loading data or resources
    setTimeout(() => {
      setLoading(false); // Set loading to false after a delay
    }, 2000); // Simulated 2-second delay
  }, []);

  return (
    <div>
      {loading ? (
        <Triangle
          visible={true}
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="triangle-loading"
          wrapperStyle={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          wrapperClass=""
        />
      ) : (
        <Router>
          <div
            className={
              isDarkMode ? "main-container dark-mode" : "main-container"
            }
          >
            <Navbar />
            {/* <div
              style={{
                width: "100%",
                margin: margin,
                background: "#00d1b2",
                position: "fixed",
                zIndex: "10",
              }}
            >
              <p
                className="title is-4 slide-in"
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Team Registration is now open for the FC GURKHA CUP 2024!
              </p>
            </div> */}
            <MyRoutes />
            <ToastContainer />
            <Footer />
          </div>
        </Router>
      )}
    </div>
  );
};

export default App;
