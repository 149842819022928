import React, { Component } from "react";
import dhirajPic from "../assests/images/dhiraj.jpeg";
import kumarPic from "../assests/images/kumar.jpeg";
import srijanPic from "../assests/images/srijan.jpeg";
import prabinPic from "../assests/images/prabin.jpeg";
import sanjibPic from "../assests/images/sanjib.jpeg";
import ajayaPic from "../assests/images/ajayajoshi.jpeg";

export default class about extends Component {
  render() {
    return (
      <div className="about">
        <div className="bd-is-cover" id="logoDiv" loading="lazy"></div>
        <div className="content" id="aboutCard">
          <h1 className="title">About Us</h1>
          <p id="aboutContent">
            <strong>Varsinais-Suomen Nepalin Urheiluyhdistys ry</strong>, was
            established on <strong>2022.06.20</strong> to unite Nepalese sports
            enthusiasts, fostering a sense of community and solidarity while
            supporting social causes. Our vision is to empower Nepali youths,
            adults, students residing in Turku region by providing the sports
            platform. Earlier, <strong>FC GURKHA</strong> was fulfilling this
            mandate since <strong>2009</strong>, which started the Football
            training and tournament in Turku to celebrate the love and passion
            for football. Since then to till the club's formal registration as a
            sports club, FC Gurkha had been actively hosting an annual all
            Nepalese <strong>7-A-side Football Tournament</strong> during the
            enchanting Finnish summer, providing a platform for athletes of all
            levels to showcase their skills and compete in a friendly
            atmosphere.
          </p>
          <p id="aboutContent">
            Our club has expanded its scope to include a wide range of sports
            activities, aiming to promote physical fitness and camaraderie among
            its members. Whether connecting with new friends or simply enjoying
            the thrill of competition,{" "}
            <strong>Varsinais-Suomen Nepalin Urheiluyhdistys ry</strong>{" "}
            embodies the spirit of sportsmanship and camaraderie.
          </p>
        </div>

        <section
          className="section boardMembersSection"
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "3rem",
          }}
        >
          <h1 className="title">Club Board Members</h1>
          <div
            className="members"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "auto",
            }}
          >
            <div
              className="card"
              style={{
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
                margin: "1rem",
              }}
            >
              <div className="card-image">
                <figure
                  className="image"
                  style={{ width: "250px", height: "250px" }}
                >
                  <img
                    src={sanjibPic}
                    alt=""
                    style={{
                      width: "250px",
                      height: "250px",
                      border: "1.2px solid lightgray",
                    }}
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div
                    className="media-content memberContent"
                    style={{ textAlign: "center" }}
                  >
                    <p className="title is-4">Sanjib Gurung</p>
                    <span className="tag is-primary is-medium">
                      <p className="subtitle is-8">Chairman</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
                margin: "1rem",
              }}
            >
              <div className="card-image">
                <figure
                  className="image"
                  style={{ width: "250px", height: "250px" }}
                >
                  <img
                    src={dhirajPic}
                    alt=""
                    style={{
                      width: "250px",
                      height: "250px",
                      border: "1.2px solid lightgray",
                    }}
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div
                    className="media-content memberContent"
                    style={{ textAlign: "center" }}
                  >
                    <p className="title is-4">Dhiraj Ghishing</p>
                    <span className="tag is-primary is-medium">
                      <p className="subtitle is-8">Vice-Chairman</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
                margin: "1rem",
              }}
            >
              <div className="card-image">
                <figure
                  className="image"
                  style={{ width: "250px", height: "250px" }}
                >
                  <img
                    src={prabinPic}
                    alt=""
                    style={{
                      width: "250px",
                      height: "250px",
                      border: "1.2px solid lightgray",
                    }}
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div
                    className="media-content memberContent"
                    style={{ textAlign: "center" }}
                  >
                    <p className="title is-4">Prabin Lama</p>
                    <span className="tag is-primary is-medium">
                      <p className="subtitle is-8">Treasurer</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
                margin: "1rem",
              }}
            >
              <div className="card-image">
                <figure
                  className="image"
                  style={{ width: "250px", height: "250px" }}
                >
                  <img
                    src={ajayaPic}
                    alt=""
                    style={{
                      width: "250px",
                      height: "250px",
                      border: "1.2px solid lightgray",
                    }}
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div
                    className="media-content memberContent"
                    style={{ textAlign: "center" }}
                  >
                    <p className="title is-4">Ajaya Joshi</p>
                    <span className="tag is-primary is-medium">
                      <p className="subtitle is-8">Secretary</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
                margin: "1rem",
              }}
            >
              <div className="card-image">
                <figure
                  className="image"
                  style={{
                    width: "250px",
                    height: "250px",
                    border: "1.2px solid lightgray",
                  }}
                >
                  <img
                    src={srijanPic}
                    alt=""
                    style={{ width: "250px", height: "250px" }}
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div
                    className="media-content memberContent"
                    style={{ textAlign: "center" }}
                  >
                    <p className="title is-4">Srijan Chapagain</p>
                    <span className="tag is-primary is-medium">
                      <p className="subtitle is-8">Member</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
                margin: "1rem",
              }}
            >
              <div className="card-image">
                <figure
                  className="image"
                  style={{
                    width: "250px",
                    height: "250px",
                    border: "1.2px solid lightgray",
                  }}
                >
                  <img
                    src={kumarPic}
                    alt=""
                    style={{ width: "250px", height: "250px" }}
                    loading="lazy"
                  />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div
                    className="media-content memberContent"
                    style={{ textAlign: "center" }}
                  >
                    <p className="title is-4">Kumar Tamang</p>
                    <span className="tag is-primary is-medium">
                      <p className="subtitle is-8">Member</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
