import React, { Component } from "react";

export default class singlePlayer extends Component {
  render() {
    return (
      <div
        className="tags-wrapper"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <span className="tag is-warning">
          {this.props.player}
          <button
            className="delete is-small"
            id="delButton"
            onClick={this.props.delete}
          ></button>
        </span>
      </div>
    );
  }
}
