/* import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Auth from "../auth/auth";
import axios from "axios";
import { toast } from "react-toastify";
import Users from "./users";
import Loader from "./loader";

export default class admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: "",
      date: "",
      time: "",
      place: "",
      dueDate: "",
      showForm: false,
      showUsersCard: false,
      allusers: [],
      isLoading: true,
    };

    this.registerEventName = this.registerEventName.bind(this);
    this.registerDate = this.registerDate.bind(this);
    this.registerDueDate = this.registerDueDate.bind(this);
    this.registerTime = this.registerTime.bind(this);
    this.registerPlace = this.registerPlace.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.controlForm = this.controlForm.bind(this);
    this.controlUsersCard = this.controlUsersCard.bind(this);
  }

  controlForm() {
    this.setState((prevState) => ({
      showForm: !prevState.showForm,
    }));
  }

  controlUsersCard() {
    this.setState((prevState) => ({
      showUsersCard: !prevState.showUsersCard,
    }));
  }

  registerEventName(e) {
    this.setState({ eventName: e.target.value });
  }
  registerDate(e) {
    this.setState({ date: e.target.value });
  }
  registerTime(e) {
    this.setState({ time: e.target.value });
  }
  registerPlace(e) {
    this.setState({ place: e.target.value });
  }
  registerDueDate(e) {
    this.setState({ dueDate: e.target.value });
  }

  //const link = 'http://localhost:3000/users/';

  onSubmit(e) {
    e.preventDefault();
    const event = {
      eventName: this.state.eventName,
      date: this.state.date,
      time: this.state.time,
      place: this.state.place,
      dueDate: this.state.dueDate,
    };

    const token = localStorage.getItem("usertoken");

    const userToken = JSON.parse(token);

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/event`, event, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        } else if (res.data.success === false) {
          toast.error(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        } else {
          toast.error(res.data.msg, {
            position: "top-center",
            theme: "colored",
          });
        }
      });

    this.setState({
      eventName: "",
      date: "",
      time: "",
      place: "",
    });
  }

  componentDidMount() {
    const token = localStorage.getItem("usertoken");
    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}allusers`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          const totalusers = res.data;

          this.setState({
            allusers: totalusers.users,
            isLoading: false,
          });
        });
    }
  }

  render() {
    if (!Auth.isUserAuthenticated()) {
      return <Navigate to="/login" />;
    }

    const { allusers, isLoading } = this.state;

    let usersCard =
      allusers &&
      allusers.map((user, i) => {
        return <Users key={i} user={user} />;
      });

    let loader = isLoading && <Loader />;
    let warningMessage = !isLoading && allusers.length <= 0 && (
      <p id="warningMessage">"No items Found"</p>
    );

    const eventForm = (
      <div className="card" id="eventregister">
        <section className="section">
          <div className="form" id="eventform">
            <label className="label">Event Name</label>
            <input
              className="input"
              type="text"
              placeholder="Event Name"
              value={this.state.eventName}
              onChange={this.registerEventName}
            />
            <label className="label">Date</label>
            <input
              className="input"
              type="date"
              placeholder="Date"
              value={this.state.date}
              onChange={this.registerDate}
            />
            <label className="label">Time</label>
            <input
              className="input"
              type="time"
              placeholder="Time"
              value={this.state.time}
              onChange={this.registerTime}
            />
            <label className="label">Place</label>
            <input
              className="input"
              type="text"
              placeholder="Place"
              value={this.state.place}
              onChange={this.registerPlace}
            />
            <label className="label">Due Date For Registration</label>
            <input
              className="input"
              type="date"
              placeholder=" Due Date For Registration"
              value={this.state.dueDate}
              onChange={this.registerDueDate}
            />
            <br />
            <button
              className="button is-medium is-primary is-fullwidth"
              type="submit"
              onClick={this.onSubmit}
            >
              Register Event
            </button>
          </div>
        </section>
      </div>
    );

    return (
      <div className="admin">
        {loader}
        {warningMessage}
        <div className="card" id="admindashboard">
          <div className="card-content">
            <h3>
              <strong>Admin Dashboard</strong>
            </h3>
            <hr />
            <button className="button is-primary" onClick={this.controlForm}>
              {this.state.showForm ? "Close Event" : "Create Event"}
            </button>
            <button
              className="button is-danger"
              onClick={this.controlUsersCard}
            >
              {this.state.showUsersCard ? "Close Users" : "Manage Users"}
            </button>
            <button className="button is-warning">
              <Link to={"/getTeams"}>Manage Teams</Link>
            </button>
          </div>
        </div>
        {this.state.showForm && eventForm}
        {this.state.showUsersCard && (
          <div className="card" id="usersCard">
            <table className="table is-responsive">
              <thead>
                <tr>
                  <th>Fullname</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Created Date</th>
                  <th>Change Role</th>
                  <th>Delete User</th>
                </tr>
              </thead>
              <tbody>{usersCard}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}
 */

import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Users from "../users";
import Loader from "../loader";
import Auth from "../../auth/auth";

const Admin = () => {
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [place, setPlace] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showUsersCard, setShowUsersCard] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!eventName || !date || !time || !place || !dueDate) {
      toast.error("All fields are required!", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }

    const event = { eventName, date, time, place, dueDate };
    const token = localStorage.getItem("usertoken");
    const userToken = JSON.parse(token);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/event`,
        event,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("An error occurred while registering the event", {
        position: "top-center",
        theme: "colored",
      });
    }

    // Clear form after submission
    setEventName("");
    setDate("");
    setTime("");
    setPlace("");
    setDueDate("");
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem("usertoken");

    if (!token) {
      toast.error("No authentication token found", {
        position: "top-center",
        theme: "colored",
      });
      return;
    }

    const userToken = JSON.parse(token);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}allusers`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`, // Ensure correct format
          },
        }
      );

      if (response.data.success) {
        setAllUsers(response.data.users || []);
      } else {
        toast.error(response.data.message || "Failed to fetch users", {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error fetching users:", error); // Log error for debugging
      toast.error("An error occurred while fetching users", {
        position: "top-center",
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  if (!Auth.isUserAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="admin">
      {isLoading && <Loader />}
      {!isLoading && allUsers.length === 0 && (
        <p id="warningMessage">No items found</p>
      )}

      <div className="card" id="admindashboard">
        <div className="card-content">
          <h3>
            <strong>Admin Dashboard</strong>
          </h3>
          <hr />
          <button
            className="button is-primary"
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? "Close Event" : "Create Event"}
          </button>
          <button
            className="button is-danger"
            onClick={() => setShowUsersCard(!showUsersCard)}
          >
            {showUsersCard ? "Close Users" : "Manage Users"}
          </button>
          <button className="button is-warning">
            <Link to={"/getTeams"}>Manage Teams</Link>
          </button>
        </div>
      </div>

      {showForm && (
        <div className="card" id="eventregister">
          <section className="section">
            <div className="form" id="eventform">
              <label className="label">Event Name</label>
              <input
                className="input"
                type="text"
                placeholder="Event Name"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
              <label className="label">Date</label>
              <input
                className="input"
                type="date"
                placeholder="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <label className="label">Time</label>
              <input
                className="input"
                type="time"
                placeholder="Time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
              <label className="label">Place</label>
              <input
                className="input"
                type="text"
                placeholder="Place"
                value={place}
                onChange={(e) => setPlace(e.target.value)}
              />
              <label className="label">Due Date For Registration</label>
              <input
                className="input"
                type="date"
                placeholder="Due Date For Registration"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
              <br />
              <button
                className="button is-medium is-primary is-fullwidth"
                type="submit"
                onClick={handleSubmit}
              >
                Register Event
              </button>
            </div>
          </section>
        </div>
      )}

      {showUsersCard && (
        <div className="card" id="usersCard">
          <table className="table is-responsive">
            <thead>
              <tr>
                <th>Fullname</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Created Date</th>
                <th>Change Role</th>
                <th>Delete User</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map((user, i) => (
                <Users key={i} user={user} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Admin;
