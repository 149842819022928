/* import React, { Component } from "react";
import axios from "axios";
import DetailsAccordion from "./detailsAccordion";
import Loader from "./loader";
import Auth from "../auth/auth";
import { Navigate } from "react-router-dom";

export default class getTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getTeams: [],
      showDetails: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}allTeams`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          this.setState({
            getTeams: res.data.teams,
            isLoading: false,
          });
        });
    }
  }

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  render() {
    if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
      return <Navigate to="/login" />;
    }
    const { getTeams, isLoading } = this.state;
    let loader = isLoading && <Loader />;
    let warningMessage = !isLoading && getTeams.length <= 0 && (
      <p id="warningMessage">"No items Found"</p>
    );
    return (
      <div className="allTeams">
        {loader}
        {warningMessage}
        <h1>Total Teams: {getTeams.length}</h1>
        {getTeams.map((team, i) => (
          <DetailsAccordion
            key={i}
            team={team}
            teamName={team.teamName}
            contactNumber={team.contactNumber}
            managerName={team.managerName}
            playersName={team.playersName}
          />
        ))}
      </div>
    );
  }
}
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import DetailsAccordion from "./detailsAccordion";
import Loader from "./loader";
/* import Auth from "../auth/auth";
import { Navigate } from "react-router-dom"; */

const GetTeams = () => {
  const [getTeams, setGetTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        //const token = localStorage.getItem("usertoken");
        /* if (token) { */
        //const userToken = JSON.parse(token);
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}allTeams`,
          {
            headers: {
              "Content-Type": "application/json",
              /* Authorization: `${userToken}`, */
            },
          }
        );

        if (response.status === 200) {
          setGetTeams(response.data.teams);
        } else {
          throw new Error("Failed to fetch teams");
        }
      } catch (err) {
        console.error("Error fetching teams:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, []);

  /* if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  } */

  // Conditional rendering
  const loader = isLoading && <Loader />;
  const warningMessage = !isLoading && getTeams.length === 0 && (
    <p id="warningMessage">No items found</p>
  );

  /* if (error) {
    return warningMessage;
  } */

  return (
    <div className="allTeams">
      {loader}
      {warningMessage}
      <h1>Total Teams: {getTeams.length}</h1>
      {getTeams.map((team, i) => (
        <DetailsAccordion
          key={i}
          team={team}
          teamName={team.teamName}
          contactNumber={team.contactNumber}
          managerName={team.managerName}
          playersName={team.playersName}
        />
      ))}
    </div>
  );
};

export default GetTeams;
