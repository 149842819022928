/* import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export default class logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: true,
    };
    //localStorage.removeItem("usertoken");
    //this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("user");
    this.setState({ loggedIn: false });
    toast.success("You have been Logged out", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  render() {
    return (
      <div>
        {this.state.loggedIn}
        <Navigate to="/" />
      </div>
    );
  }
}
 */

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";

const Logout = () => {
  const [loggedOut, setLoggedOut] = useState(false);
  const { deauthenticateUser } = useAuth();

  useEffect(() => {
    const logout = async () => {
      await deauthenticateUser();
      toast.success("You have been logged out!", {
        toastId: "logout",
        position: "top-center",
        theme: "colored",
      });
      setLoggedOut(true);
    };
    logout();
  }, [deauthenticateUser]);

  if (loggedOut) {
    return <Navigate to="/" />;
  }

  return <div>Logging out...</div>;
};

export default Logout;
