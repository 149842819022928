import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import Auth from "../auth/auth";
import Loader from "./loader";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../contexts/AuthContext";

const Profile = () => {
  const [profile, setProfile] = useState({
    name: "",
    username: "",
    email: "",
    isVerified: false,
    role: "",
    id: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const { isUserAuthenticated } = useAuth();
  const isAuthenticatedUser = isUserAuthenticated();

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}profile`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            setProfile({
              name: res.data.user.name,
              username: res.data.user.username,
              email: res.data.user.email,
              isVerified: res.data.user.isVerified,
              role: res.data.user.role,
              id: res.data.user.id,
            });
            setIsLoggedIn(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching profile data:", err);
          setIsLoading(false);
        });
    }
  }, []);

  if (!isAuthenticatedUser || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="profile" style={{ marginTop: "4.7rem" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <nav className="panel">
            <p className="panel-heading">
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
              <span> </span>
              <strong>Your Profile</strong>
            </p>
            <label className="panel-block is-active">
              <strong>Name: {profile.name}</strong>
            </label>
            <label className="panel-block">
              <strong>Username: {profile.username}</strong>
            </label>
            <label className="panel-block">
              <strong>Email: {profile.email}</strong>
            </label>
            <label className="panel-block">
              <strong>
                Email Verified: {profile.isVerified ? "Yes" : "No"}
              </strong>
            </label>
            <label className="panel-block" id="rolePanel">
              <strong>
                Role:{" "}
                {profile.role === "player"
                  ? "Team Manager"
                  : profile.role.toUpperCase()}
              </strong>
              {Auth.checkRole() && (
                <button className="button is-success" id="adminBtn">
                  <Link to={"/admin"} style={{ color: "#fff" }}>
                    Admin Dashboard
                  </Link>
                </button>
              )}
            </label>
          </nav>
          {!isMobile && (
            <div
              className="panel-divider"
              style={{ width: "0.3rem", height: "300px", background: "black" }}
            ></div>
          )}
          <div className="panel-block">
            <h4 className="title is-4" style={{ textDecoration: "underline" }}>
              Links
            </h4>
            <button
              className="button is-primary is-fullwidth"
              style={{ marginBottom: "1rem" }}
            >
              <Link to={"/events"} style={{ color: "#fff" }}>
                Registration for The Tournament
              </Link>
            </button>

            <button className="button is-link is-fullwidth">
              <Link to={`/myEvents/${profile.id}`} style={{ color: "#fff" }}>
                Go To Your Registered Events
              </Link>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
