import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "./contexts/themeContext";
/* import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend"; */
import { AuthProvider } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        {/* <DndProvider backend={HTML5Backend}> */}
        <App />
        {/* </DndProvider> */}
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
