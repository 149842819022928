import React from "react";
import { useTheme } from "../contexts/themeContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const ThemeToggleButton = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  const handleClick = (event) => {
    event.stopPropagation();
    toggleTheme();
  };

  return (
    <button
      className={
        isDarkMode
          ? "button is-light is-outlined"
          : "button is-dark is-outlined"
      }
      onClick={handleClick}
      aria-label={isDarkMode ? "Light Mode" : "Dark Mode"}
    >
      <span className="icon">
        <FontAwesomeIcon icon={isDarkMode ? faMoon : faSun} />
      </span>
    </button>
  );
};

export default ThemeToggleButton;
