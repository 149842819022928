/* import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SinglePlayer from "./singlePlayer";
import Auth from "../auth/auth";
import { Navigate, useNavigate } from "react-router-dom";

export default class RegisterTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamName: "",
      playersName: [],
      currentPlayer: "",
      contactNumber: "",
      managerName: "",
    };

    this.registerTeamName = this.registerTeamName.bind(this);
    this.registerPlayersName = this.registerPlayersName.bind(this);
    this.registerContactNumber = this.registerContactNumber.bind(this);
    this.registerManagerName = this.registerManagerName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  registerTeamName(e) {
    this.setState({ teamName: e.target.value });
  }
  registerPlayersName(e) {
    this.setState({ currentPlayer: e.target.value });
  }
  registerContactNumber(e) {
    this.setState({ contactNumber: e.target.value });
  }
  registerManagerName(e) {
    this.setState({ managerName: e.target.value });
  }

  areFieldsValid = () => {
    if (
      this.state.teamName &&
      this.state.playersName.length >= 7 &&
      this.state.contactNumber.length === 10 &&
      this.state.managerName
    ) {
      return true;
    } else {
      toast.error("Invalid or Empty Fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  };

  onAdd = () => {
    let playerCopy = this.state.playersName.slice();
    playerCopy.push(this.state.currentPlayer);

    if (this.state.currentPlayer) {
      this.setState({ playersName: playerCopy, currentPlayer: "" });
    }
  };

  onDelete = (i) => {
    let playerCopy = this.state.playersName.slice();
    playerCopy.splice(i, 1);

    this.setState({ playersName: playerCopy });
  };

  goback = () => {
    useNavigate(-1);
  };

  onSubmit(e) {
    e.preventDefault();
    const registerTeam = {
      teamName: this.state.teamName,
      playersName: this.state.playersName,
      contactNumber: this.state.contactNumber,
      managerName: this.state.managerName,
    };

    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      if (this.areFieldsValid()) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}registerTeam/` +
              this.props.match.params.id,
            registerTeam,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `${userToken}`,
              },
            }
          )
          .then((res) => {
            if (res.data.success === true) {
              toast.success(res.data.msg, {
                position: toast.POSITION.TOP_CENTER,
              });
              this.setState({
                teamName: "",
                playersName: [],
                contactNumber: "",
                managerName: "",
              });
            } else if (res.data.success === false) {
              toast.error(res.data.msg, {
                position: toast.POSITION.TOP_CENTER,
              });
              this.setState({
                teamName: this.state.teamName,
                playersName: this.state.playersName,
                contactNumber: this.state.contactNumber,
                managerName: this.state.managerName,
              });
            } else {
              toast.error(res.data.msg, {
                position: toast.POSITION.TOP_CENTER,
              });
              this.setState({
                teamName: this.state.teamName,
                playersName: this.state.playersName,
                contactNumber: this.state.contactNumber,
                managerName: this.state.managerName,
              });
            }
          });
      }
    }
  }

  render() {
    if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
      return <Navigate to="/login" />;
    }

    let bulletedPlayers = this.state.playersName.map((e, i) => {
      return (
        <SinglePlayer key={i} player={e} delete={() => this.onDelete(i)} />
      );
    });
    return (
      <div className="teamPage">
        <div id="teamForm">
          <p className="title" id="title">
            Register Your Team here
          </p>
          <hr />
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Team Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    required
                    placeholder="Participating Team Name"
                    value={this.state.teamName}
                    onChange={this.registerTeamName}
                  />
                </div>
                <ul id="playerTag" style={{ flexDirection: "row" }}>
                  {bulletedPlayers}
                </ul>
                {this.state.playersName.length <= 6 && (
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <p className="help is-danger">
                          Please Add atleast 7 players
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Player's Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    required
                    placeholder="Add Player"
                    value={this.state.currentPlayer}
                    onChange={this.registerPlayersName}
                    onKeyPress={(event) => {
                      event.key === "Enter" && this.onAdd();
                    }}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user"></i>
                  </span>
                  <button
                    className="button is-primary is-right"
                    onClick={this.onAdd}
                  >
                    Add Player
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Contact Number</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    required
                    placeholder="Contact Number"
                    value={this.state.contactNumber}
                    onChange={this.registerContactNumber}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Manager's Name</label>
            </div>
            <div className="field-body" id="managerName">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    required
                    placeholder="Participating Team Manager's Name"
                    value={this.state.managerName}
                    onChange={this.registerManagerName}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label"></div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    type="submit"
                    onClick={this.onSubmit}
                    style={{ marginRight: "1rem" }}
                  >
                    Register Your Team
                  </button>
                  <button
                    className="button is-warning"
                    type="submit"
                    onClick={() => {
                      this.goback();
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 */

/* import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SinglePlayer from "./singlePlayer";
import Auth from "../auth/auth";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const RegisterTeam = () => {
  const [teamName, setTeamName] = useState("");
  const [playersName, setPlayersName] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [managerName, setManagerName] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const registerTeamName = (e) => setTeamName(e.target.value);
  const registerPlayersName = (e) => setCurrentPlayer(e.target.value);
  const registerContactNumber = (e) => setContactNumber(e.target.value);
  const registerManagerName = (e) => setManagerName(e.target.value);

  const areFieldsValid = () => {
    if (
      teamName &&
      playersName.length >= 7 &&
      contactNumber.length === 10 &&
      managerName
    ) {
      return true;
    } else {
      toast.error("Invalid or Empty Fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  };

  const onAdd = () => {
    if (currentPlayer) {
      setPlayersName([...playersName, currentPlayer]);
      setCurrentPlayer("");
    }
  };

  const onDelete = (i) => {
    const playerCopy = playersName.slice();
    playerCopy.splice(i, 1);
    setPlayersName(playerCopy);
  };

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const registerTeam = { teamName, playersName, contactNumber, managerName };
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      if (areFieldsValid()) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}registerTeam/${id}`,
            registerTeam,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `${userToken}`,
              },
            }
          )
          .then((res) => {
            if (res.data.success === true) {
              toast.success(res.data.msg, {
                position: toast.POSITION.TOP_CENTER,
              });
              setTeamName("");
              setPlayersName([]);
              setContactNumber("");
              setManagerName("");
            } else {
              toast.error(res.data.msg, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      }
    }
  };

  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  const bulletedPlayers = playersName.map((e, i) => (
    <SinglePlayer key={i} player={e} delete={() => onDelete(i)} />
  ));

  return (
    <div className="teamPage">
      <div id="teamForm">
        <p className="title" id="title">
          Register Your Team here
        </p>
        <hr />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Team Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Participating Team Name"
                  value={teamName}
                  onChange={registerTeamName}
                />
              </div>
              <ul id="playerTag" style={{ flexDirection: "row" }}>
                {bulletedPlayers}
              </ul>
              {playersName.length <= 6 && (
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <p className="help is-danger">
                        Please Add at least 7 players
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Player's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Add Player"
                  value={currentPlayer}
                  onChange={registerPlayersName}
                  onKeyPress={(event) => {
                    event.key === "Enter" && onAdd();
                  }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
                <button className="button is-primary is-right" onClick={onAdd}>
                  Add Player
                </button>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Contact Number</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={registerContactNumber}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Manager's Name</label>
          </div>
          <div className="field-body" id="managerName">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Participating Team Manager's Name"
                  value={managerName}
                  onChange={registerManagerName}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={onSubmit}
                  style={{ marginRight: "1rem" }}
                >
                  Register Your Team
                </button>
                <button
                  className="button is-warning"
                  type="submit"
                  onClick={goBack}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterTeam; */

import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SinglePlayer from "./singlePlayer";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../contexts/AuthContext";

const RegisterTeam = () => {
  const [teamName, setTeamName] = useState("");
  const [playersName, setPlayersName] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [managerName, setManagerName] = useState("");

  const { isUserAuthenticated } = useAuth();
  const isAuthenticatedUser = isUserAuthenticated();

  const navigate = useNavigate();
  const { id } = useParams();

  const registerTeamName = (e) => setTeamName(e.target.value);
  const registerPlayersName = (e) => setCurrentPlayer(e.target.value);
  const registerContactNumber = (e) => setContactNumber(e.target.value);
  const registerManagerName = (e) => setManagerName(e.target.value);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const areFieldsValid = () => {
    // Validate fields
    if (
      teamName &&
      playersName.length >= 7 &&
      playersName.length <= 14 &&
      contactNumber.length === 10 &&
      managerName
    ) {
      return true;
    } else {
      let errorMessage = "Invalid or Empty Fields";

      // Customize the error message based on specific validation failures
      if (!teamName) errorMessage = "Team Name is required.";
      else if (playersName.length < 7)
        errorMessage = "At least 7 players are required.";
      else if (playersName.length > 14)
        errorMessage = "No more than 14 players are allowed.";
      else if (contactNumber.length !== 10)
        errorMessage = "Contact Number must be 10 digits.";
      else if (!managerName) errorMessage = "Manager Name is required.";

      toast.error(errorMessage, {
        position: "top-center",
        theme: "colored",
      });

      return false;
    }
  };

  const onAdd = () => {
    if (currentPlayer) {
      setPlayersName([...playersName, currentPlayer]);
      setCurrentPlayer("");
    }
  };

  const onDelete = (i) => {
    const playerCopy = playersName.slice();
    playerCopy.splice(i, 1);
    setPlayersName(playerCopy);
  };

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const registerTeam = { teamName, playersName, contactNumber, managerName };
    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      if (areFieldsValid()) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}registerTeam/${id}`,
            registerTeam,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `${userToken}`,
              },
            }
          )
          .then((res) => {
            if (res.data.success === true) {
              toast.success(res.data.msg, {
                position: "top-center",
                theme: "colored",
              });
              setTeamName("");
              setPlayersName([]);
              setContactNumber("");
              setManagerName("");

              return navigate("/profile");
            } else {
              toast.error(res.data.msg, {
                position: "top-center",
                theme: "colored",
              });
            }
          });
      }
    }
  };

  if (!isAuthenticatedUser) {
    return <Navigate to="/login" />;
  }

  const bulletedPlayers = playersName.map((e, i) => (
    <SinglePlayer key={i} player={e} delete={() => onDelete(i)} />
  ));

  return (
    <div
      className="teamPage"
      style={isMobile ? { padding: "1rem" } : { padding: "3rem" }}
    >
      <div
        id="teamForm"
        style={isMobile ? { margin: "1rem" } : { margin: "3rem" }}
      >
        <p className="title" id="title">
          Register Your Team here
        </p>
        <hr />
        <div
          className="field is-horizontal"
          style={isMobile ? { flexDirection: "column" } : {}}
        >
          <div className="field-label is-normal">
            <label className="label">Team Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Participating Team Name"
                  value={teamName}
                  onChange={registerTeamName}
                />
              </div>
              <ul
                id="playerTag"
                style={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                {bulletedPlayers}
              </ul>
              {playersName.length <= 6 && (
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <p className="help is-danger">
                        Please Add at least 7 players
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {playersName.length > 14 && (
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <p className="help is-danger">
                        Maximum 14 players are allowed
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="field is-horizontal"
          style={isMobile ? { flexDirection: "column" } : {}}
        >
          <div className="field-label is-normal">
            <label className="label">Player's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Add Player"
                  value={currentPlayer}
                  onChange={registerPlayersName}
                  onKeyPress={(event) => {
                    event.key === "Enter" && onAdd();
                  }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
                <button className="button is-primary is-right" onClick={onAdd}>
                  Add Player
                </button>
              </p>
            </div>
          </div>
        </div>

        <div
          className="field is-horizontal"
          style={isMobile ? { flexDirection: "column" } : {}}
        >
          <div className="field-label is-normal">
            <label className="label">Contact Number</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={registerContactNumber}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="field is-horizontal"
          style={isMobile ? { flexDirection: "column" } : {}}
        >
          <div className="field-label is-normal">
            <label className="label">Manager's Name</label>
          </div>
          <div className="field-body" id="managerName">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Participating Team Manager's Name"
                  value={managerName}
                  onChange={registerManagerName}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="field is-horizontal"
          style={isMobile ? { flexDirection: "column" } : {}}
        >
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={onSubmit}
                  style={{ marginRight: "1rem" }}
                >
                  Register Your Team
                </button>
                <button
                  className="button is-warning"
                  type="submit"
                  onClick={goBack}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterTeam;
