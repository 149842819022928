/* import React, { Component } from "react";
import axios from "axios";
import SinglePlayer from "./singlePlayer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../auth/auth";
import { Navigate } from "react-router-dom";

export default class updateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamName: "",
      playersName: [],
      currentPlayer: "",
      contactNumber: "",
      managerName: "",
      user: "",
      isUpdated: false,
    };

    this.registerTeamName = this.registerTeamName.bind(this);
    this.registerPlayersName = this.registerPlayersName.bind(this);
    this.registerContactNumber = this.registerContactNumber.bind(this);
    this.registerManagerName = this.registerManagerName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  registerTeamName(e) {
    this.setState({ teamName: e.target.value });
  }
  registerPlayersName(e) {
    this.setState({ currentPlayer: e.target.value });
  }
  registerContactNumber(e) {
    this.setState({ contactNumber: e.target.value });
  }
  registerManagerName(e) {
    this.setState({ managerName: e.target.value });
  }

  onAdd = () => {
    let playerCopy = this.state.playersName.slice();
    playerCopy.push(this.state.currentPlayer);

    this.setState({ playersName: playerCopy, currentPlayer: "" });
  };

  onDelete = (i) => {
    let playerCopy = this.state.playersName.slice();
    playerCopy.splice(i, 1);

    this.setState({ playersName: playerCopy });
  };

  goback = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    const token = localStorage.getItem("usertoken");
    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT}editBooking/` +
            this.props.match.params.id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        )
        .then((res) => {
          res.data.editBooking.map((r) => {
            return this.setState({
              teamName: r.teamName,
              playersName: r.playersName,
              contactNumber: r.contactNumber,
              managerName: r.managerName,
              user: r.user,
            });
          });
        });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const updateTeam = {
      teamName: this.state.teamName,
      playersName: this.state.playersName,
      contactNumber: this.state.contactNumber,
      managerName: this.state.managerName,
    };

    const token = localStorage.getItem("usertoken");

    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}updateMyBookings/` +
            this.props.match.params.id,
          updateTeam,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            this.setState({ isUpdated: true });
            this.props.history.push("/myEvents/" + this.state.user);
            toast.success(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.data.success === false) {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    }
  }

  render() {
    if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
      return <Navigate to="/login" />;
    }

    let bulletedPlayers = this.state.playersName.map((e, i) => {
      return (
        <SinglePlayer key={i} player={e} delete={() => this.onDelete(i)} />
      );
    });

    return (
      <div className="teamPage">
        <div id="teamForm">
          <p className="title" id="title">
            Edit here
          </p>
          <hr />
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Team Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Participating Team Name"
                    value={this.state.teamName}
                    onChange={this.registerTeamName}
                  />
                </div>
                <ul id="playerTag">
                  {this.state.playersName.length === 0 ? (
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <p className="help is-danger">
                            Please Add atleast 7 players
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    bulletedPlayers
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Player's Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    className="input"
                    type="text"
                    placeholder="Add Player"
                    value={this.state.currentPlayer}
                    onChange={this.registerPlayersName}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user"></i>
                  </span>
                  <button
                    className="button is-primary is-right"
                    onClick={this.onAdd}
                  >
                    Add Player
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Contact Number</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Contact Number"
                    value={this.state.contactNumber}
                    onChange={this.registerContactNumber}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Team Manager's Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Participating Team Manager's Name"
                    value={this.state.managerName}
                    onChange={this.registerManagerName}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label"></div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    type="submit"
                    onClick={this.onSubmit}
                    style={{ marginRight: "1rem" }}
                  >
                    Update Your Team
                  </button>
                  <button
                    className="button is-warning"
                    type="submit"
                    onClick={() => {
                      this.goback();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 */

/* import React, { useState, useEffect } from "react";
import axios from "axios";
import SinglePlayer from "./singlePlayer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../auth/auth";
import { Navigate, useParams, useNavigate } from "react-router-dom";

const UpdateEvent = () => {
  const [teamName, setTeamName] = useState("");
  const [playersName, setPlayersName] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [managerName, setManagerName] = useState("");
  const [user, setUser] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("usertoken");
    if (token) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}editBooking/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          res.data.editBooking.map((r) => {
            setTeamName(r.teamName);
            setPlayersName(r.playersName);
            setContactNumber(r.contactNumber);
            setManagerName(r.managerName);
            setUser(r.user);
          });
        });
    }
  }, [id]);

  const registerTeamName = (e) => {
    setTeamName(e.target.value);
  };

  const registerPlayersName = (e) => {
    setCurrentPlayer(e.target.value);
  };

  const registerContactNumber = (e) => {
    setContactNumber(e.target.value);
  };

  const registerManagerName = (e) => {
    setManagerName(e.target.value);
  };

  const onAdd = () => {
    let playerCopy = [...playersName];
    playerCopy.push(currentPlayer);
    setPlayersName(playerCopy);
    setCurrentPlayer("");
  };

  const onDelete = (i) => {
    let playerCopy = [...playersName];
    playerCopy.splice(i, 1);
    setPlayersName(playerCopy);
  };

  const goback = () => {
    navigate(-1);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const updateTeam = {
      teamName,
      playersName,
      contactNumber,
      managerName,
    };

    const token = localStorage.getItem("usertoken");
    if (token) {
      const userToken = JSON.parse(token);

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}updateMyBookings/${id}`,
          updateTeam,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            setIsUpdated(true);
            navigate(`/myEvents/${user}`);
            toast.success(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    }
  };

  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  let bulletedPlayers = playersName.map((e, i) => {
    return <SinglePlayer key={i} player={e} delete={() => onDelete(i)} />;
  });

  return (
    <div className="teamPage">
      <div id="teamForm">
        <p className="title" id="title">
          Edit here
        </p>
        <hr />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Team Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Participating Team Name"
                  value={teamName}
                  onChange={registerTeamName}
                />
              </div>
              <ul id="playerTag">
                {playersName.length === 0 ? (
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <p className="help is-danger">
                          Please Add at least 7 players
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  bulletedPlayers
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Player's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="Add Player"
                  value={currentPlayer}
                  onChange={registerPlayersName}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
                <button className="button is-primary is-right" onClick={onAdd}>
                  Add Player
                </button>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Contact Number</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={registerContactNumber}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Team Manager's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Participating Team Manager's Name"
                  value={managerName}
                  onChange={registerManagerName}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={onSubmit}
                  style={{ marginRight: "1rem" }}
                >
                  Update Your Team Details
                </button>
                <button
                  className="button is-warning"
                  type="submit"
                  onClick={goback}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEvent; */

/* import React, { useState, useEffect } from "react";
import axios from "axios";
import SinglePlayer from "./singlePlayer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../auth/auth";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const UpdateEvent = () => {
  const [teamName, setTeamName] = useState("");
  const [playersName, setPlayersName] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [managerName, setManagerName] = useState("");
  const [user, setUser] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    const token = localStorage.getItem("usertoken");
    if (token) {
      const userToken = JSON.parse(token);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}editBooking/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        })
        .then((res) => {
          console.log(res);
          res.data.editBooking?.map((r) => {
            setTeamName(r.teamName);
            setPlayersName(r.playersName);
            setContactNumber(r.contactNumber);
            setManagerName(r.managerName);
            setUser(r.user);
          });
        });
    }
  }, [id]);

  const registerTeamName = (e) => {
    setTeamName(e.target.value);
  };

  const registerPlayersName = (e) => {
    setCurrentPlayer(e.target.value);
  };

  const registerContactNumber = (e) => {
    setContactNumber(e.target.value);
  };

  const registerManagerName = (e) => {
    setManagerName(e.target.value);
  };

  const onAdd = () => {
    let playerCopy = [...playersName];
    playerCopy.push(currentPlayer);
    setPlayersName(playerCopy);
    setCurrentPlayer("");
  };

  const onDelete = (i) => {
    let playerCopy = [...playersName];
    playerCopy.splice(i, 1);
    setPlayersName(playerCopy);
  };

  const goback = () => {
    navigate(-1);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const updateTeam = {
      teamName,
      playersName,
      contactNumber,
      managerName,
    };

    const token = localStorage.getItem("usertoken");
    if (token) {
      const userToken = JSON.parse(token);

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}updateMyBookings/${id}`,
          updateTeam,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            setIsUpdated(true);
            navigate(`/myEvents/${user}`);
            toast.success(res.data.msg, {
              position: "top-center",
              theme: "colored",
            });
          } else {
            toast.error(res.data.msg, {
              position: "top-center",
              theme: "colored",
            });
          }
        });
    }
  };

  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  let bulletedPlayers = playersName.map((e, i) => {
    return <SinglePlayer key={i} player={e} delete={() => onDelete(i)} />;
  });

  const formContainerStyle = isMobile
    ? { padding: "1rem" }
    : { padding: "3rem" };
  const formStyle = isMobile ? { margin: "1rem" } : { margin: "3rem" };
  const fieldStyle = isMobile ? { flexDirection: "column" } : {};

  return (
    <div className="teamPage" style={formContainerStyle}>
      <div id="teamForm" style={formStyle}>
        <p className="title" id="title">
          Edit here
        </p>
        <hr />
        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Team Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Participating Team Name"
                  value={teamName}
                  onChange={registerTeamName}
                />
              </div>
              <ul id="playerTag">
                {playersName.length === 0 ? (
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <p className="help is-danger">
                          Please Add at least 7 players
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  bulletedPlayers
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Player's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="Add Player"
                  value={currentPlayer}
                  onChange={registerPlayersName}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
                <button className="button is-primary is-right" onClick={onAdd}>
                  Add Player
                </button>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Contact Number</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={registerContactNumber}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Team Manager's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Participating Team Manager's Name"
                  value={managerName}
                  onChange={registerManagerName}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={onSubmit}
                  style={{ marginRight: "1rem" }}
                >
                  Update Your Team Details
                </button>
                <button
                  className="button is-warning"
                  type="submit"
                  onClick={goback}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEvent; */

import React, { useState, useEffect } from "react";
import axios from "axios";
import SinglePlayer from "./singlePlayer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../auth/auth";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const UpdateEvent = () => {
  const [teamName, setTeamName] = useState("");
  const [playersName, setPlayersName] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [managerName, setManagerName] = useState("");
  const [user, setUser] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  // Media query hooks
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // Fetch event data on component mount
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const token = localStorage.getItem("usertoken");
        if (!token) throw new Error("No token found");

        const userToken = JSON.parse(token);
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}editBooking/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        );

        const { editBooking } = response.data;
        if (editBooking) {
          console.log(editBooking);
          const { teamName, playersName, contactNumber, managerName, user } =
            editBooking;
          setTeamName(teamName);
          setPlayersName(playersName);
          setContactNumber(contactNumber);
          setManagerName(managerName);
          setUser(user);
        }
      } catch (error) {
        console.error("Failed to fetch event data:", error);
        toast.error("Failed to load event data.", {
          position: "top-center",
          theme: "colored",
        });
      }
    };

    fetchEventData();
  }, [id]);

  // Handler functions
  const registerTeamName = (e) => setTeamName(e.target.value);
  const registerPlayersName = (e) => setCurrentPlayer(e.target.value);
  const registerContactNumber = (e) => setContactNumber(e.target.value);
  const registerManagerName = (e) => setManagerName(e.target.value);

  const onAdd = () => {
    if (currentPlayer) {
      setPlayersName((prev) => [...prev, currentPlayer]);
      setCurrentPlayer("");
    }
  };

  const onDelete = (index) => {
    setPlayersName((prev) => prev.filter((_, i) => i !== index));
  };

  const goback = () => navigate(-1);

  const onSubmit = async (e) => {
    e.preventDefault();

    const updateTeam = { teamName, playersName, contactNumber, managerName };

    try {
      const token = localStorage.getItem("usertoken");
      if (!token) throw new Error("No token found");

      const userToken = JSON.parse(token);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}updateMyBookings/${id}`,
        updateTeam,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        }
      );

      if (response.data.success) {
        setIsUpdated(true);
        navigate(`/myEvents/${user}`);
        toast.success(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Failed to update team:", error);
      toast.error("Failed to update team.", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  // Redirect if not authenticated
  if (!Auth.isUserAuthenticated() || Auth.isTokenExpired()) {
    return <Navigate to="/login" />;
  }

  // Player list rendering
  const bulletedPlayers = playersName.map((player, index) => (
    <SinglePlayer key={index} player={player} delete={() => onDelete(index)} />
  ));

  // Responsive styling
  const formContainerStyle = isMobile
    ? { padding: "1rem" }
    : { padding: "3rem" };
  const formStyle = isMobile ? { margin: "1rem" } : { margin: "3rem" };
  const fieldStyle = isMobile ? { flexDirection: "column" } : {};

  return (
    <div className="teamPage" style={formContainerStyle}>
      <div id="teamForm" style={formStyle}>
        <p className="title" id="title">
          Edit here
        </p>
        <hr />
        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Team Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Participating Team Name"
                  value={teamName}
                  onChange={registerTeamName}
                />
              </div>
              <ul id="playerTag">
                {playersName.length === 0 ? (
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <p className="help is-danger">
                          Please Add at least 7 players
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  bulletedPlayers
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Player's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="Add Player"
                  value={currentPlayer}
                  onChange={registerPlayersName}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
                <button className="button is-primary is-right" onClick={onAdd}>
                  Add Player
                </button>
              </p>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Contact Number</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={registerContactNumber}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label is-normal">
            <label className="label">Team Manager's Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Participating Team Manager's Name"
                  value={managerName}
                  onChange={registerManagerName}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal" style={fieldStyle}>
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={onSubmit}
                  style={{ marginRight: "1rem" }}
                >
                  Update Your Team Details
                </button>
                <button
                  className="button is-warning"
                  type="button"
                  onClick={goback}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEvent;
