/* import React from "react";
import { useMediaQuery } from "react-responsive";
import himalayanKitchenPic from "../assests/images/himalayankitchen.JPG";
import theMomoCafePic from "../assests/images/themomocafe.JPG";
import pikkuPihviPic from "../assests/images/pikkupihvi.JPG";

const SponsorBanner = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const logos = [
    { id: 1, src: pikkuPihviPic, alt: "Restaurant 1" },
    { id: 2, src: himalayanKitchenPic, alt: "Restaurant 2" },
    { id: 3, src: theMomoCafePic, alt: "Restaurant 3" },
  ];

  return (
    <section className="sponsors-section">
      <h2
        className="title"
        style={{ marginBottom: "3rem", textAlign: "center" }}
      >
        Our Sponsors
      </h2>
      <div className="banner">
        <div className="container">
          <div className="columns is-vcentered is-multiline">
            {logos.map((logo) => (
              <div
                key={logo.id}
                className="column is-one-third has-text-centered"
              >
                <figure className="image is-inline-block sponsor-logo-container">
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="sponsor-logo-image"
                    style={{ height: "150px", width: "150px" }}
                  />
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SponsorBanner;
 */

import React from "react";
import { useMediaQuery } from "react-responsive";
import himalayanKitchenPic from "../assests/images/himalayankitchen.JPG";
import theMomoCafePic from "../assests/images/themomocafe.JPG";
import pikkuPihviPic from "../assests/images/pikkupihvi.JPG";

const SponsorBanner = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const logos = [
    {
      id: 1,
      src: himalayanKitchenPic,
      alt: "Himalayan Kitchen",
      name: "Himalayan Kitchen",
      url: "http://himalayankitchen.fi/",
      className: "himalayan-kitchen-logo",
    },
    {
      id: 2,
      src: pikkuPihviPic,
      alt: "Pikku Pihvi",
      name: "Ravintola Pikkupihvi",
      url: "https://www.pikkupihvi.fi/",
      className: "pikkupihvi-logo",
    },
    {
      id: 3,
      src: theMomoCafePic,
      alt: "The Momo Cafe",
      name: "The Momo Cafe",
      url: "https://themomocafefi.wixsite.com/turku",
      className: "themomocafe-logo",
    },
  ];

  return (
    <section className="sponsors-section">
      <h2
        className="title"
        style={{ marginBottom: "3rem", textAlign: "center" }}
      >
        Our Sponsors
      </h2>
      {/* <div className="banner">
        <div className="container"> */}
      <div className="columns is-vcentered is-multiline">
        {logos.map((logo) => (
          <div
            key={logo.id}
            className="column is-one-third has-text-centered image-column-container"
          >
            <figure className="image is-inline-block sponsor-logo-container">
              <img src={logo.src} alt={logo.alt} className={logo.className} />
            </figure>
            <a
              href={logo.url}
              target="_blank"
              rel="noopener noreferrer"
              className="restaurant-link is-link"
            >
              {logo.name}
            </a>
          </div>
        ))}
      </div>
      {/* </div>
      </div> */}
    </section>
  );
};

export default SponsorBanner;
