const jwtDecode = require("jwt-decode");
//const jwt = require("jsonwebtoken");

export default class Auth {
  static saveUserData(token, user) {
    localStorage.setItem("usertoken", token);
    localStorage.setItem("user", JSON.stringify(user));
  }

  static loadUserData(token, user) {
    localStorage.getItem("usertoken", token);
    localStorage.getItem("user", JSON.parse(user));
  }

  static isUserAuthenticated() {
    //const token = this.getToken();
    const token = localStorage.getItem("usertoken");
    if (!token) {
      return false;
    }
    if (token && token.length > 10) {
      return true;
    } else {
      return false;
    }
  }

  static checkRole() {
    const user = JSON.parse(localStorage.getItem("user"));
    //const user = JSON.parse(localStorage.getItem('user'));
    //const token = localStorage.getItem('usertoken');
    const role = user.role;
    if (role === "superAdmin" || role === "admin") {
      return true;
    } else {
      //console.log('no admin');
      return false;
    }
  }

  static deauthenticateUser() {
    localStorage.removeItem("usertoken");
  }

  static getToken() {
    return localStorage.getItem("usertoken");
  }

  static isTokenExpired() {
    const mytoken = this.getToken();
    if (mytoken) {
      const decoded = jwtDecode(mytoken);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.clear();
        return true;
      } else {
        return false;
      }
    }

    /* var isExpired = false;
    const mytoken = this.getToken();
    const decoded = jwtDecode(mytoken);
    console.log(decoded);
    const token = localStorage.getItem("usertoken");
    var decodedToken = jwt.decode(token);
    console.log(decodedToken);
    var dateNow = new Date(); */

    /* if (decodedToken.exp < dateNow.getTime()) {
      isExpired = true;
      console.log("token expired");
    } */
  }
}
