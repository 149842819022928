import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <progress
        className="progress is-small is-primary"
        max="100"
        style={{ width: "50%" }}
      />
    );
  }
}

export default Loader;
