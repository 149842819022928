import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleted: false,
    };
    this.deleteUser = this.deleteUser.bind(this);
  }

  deleteUser() {
    const token = localStorage.getItem("usertoken");
    if (token !== null) {
      const userToken = JSON.parse(token);

      axios
        .delete(
          `${process.env.REACT_APP_API_ENDPOINT}singleUser/` +
            this.props.user._id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `${userToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            isDeleted: true,
          });

          if (res.data.success === true) {
            toast.success(res.data.msg, {
              position: "top-center",
              theme: "colored",
            });
          } else if (res.data.success === false) {
            toast.error(res.data.msg, {
              position: "top-center",
              theme: "colored",
            });
          }
        });
    }
  }

  render() {
    return (
      <tr>
        <td>{this.props.user.name}</td>
        <td>{this.props.user.username}</td>
        <td>{this.props.user.email}</td>
        <td>{this.props.user.role}</td>
        <td>{this.props.user.createdAt}</td>
        <td>
          <button className="button is-warning">Change Role</button>
        </td>
        <td>
          <button className="button is-danger" onClick={this.deleteUser}>
            Delete User
          </button>
        </td>
      </tr>
    );
  }
}
