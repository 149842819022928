import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Auth from "../auth/auth";
import handHoldSports from "../assests/images/handholdsports.jpg";
import resimatorLogo from "../assests/images/resimatorlogo.jpeg";
import NimenhuutoCalendarWidget from "./nimenhuutoCalendarWidget";
import NimenhuutoEventWidget from "./nimenhuutoEventWidget";
import SponsorBanner from "./sponsors";

const Home = () => {
  const [isToken, setIsToken] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showEvents, setShowEvents] = useState(false);

  const toggleCalendarWidget = () => {
    //setShowCalendar(!showCalendar);
    setShowCalendar(true);
    setShowEvents(false);
  };

  const toggleEventWidget = () => {
    //setShowCalendar(!showCalendar);
    setShowCalendar(false);
    setShowEvents(true);
  };
  useEffect(() => {
    if (Auth.isUserAuthenticated()) {
      setIsToken(true);
    }
  }, []);

  return (
    <div className="homePage">
      <div className="homeBanner">
        <section
          className="hero"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <div className="hero-body welcomeTexthome">
            <p className="title is-3" style={{ marginBottom: "2rem" }}>
              Welcome to Varsinais-Suomen Nepalin Urheiluyhdistys
            </p>
            <p className="subtitle is-4">
              "Where Champions Rise, Dreams Soar, and Passion Unites"
            </p>
          </div>
        </section>
        <div className="card" id="homecard">
          <div className="image homeImage" loading="lazy" />
          <div className="sectionHome">
            <h1 className="sectionText">
              Together, <br /> We Can! <br /> We Achieve More.
              {!isToken && (
                <Link
                  to={"/login"}
                  className="button is-large is-link is-outlined"
                  id="getStarted"
                >
                  Get Started
                </Link>
              )}
            </h1>
          </div>
        </div>
      </div>
      <section
        className="section aboutSection"
        style={{ margin: "0 80px 0 80px", width: "auto", padding: "0" }}
      >
        <div className="container">
          <div
            className="columns is-vcentered"
            style={{ marginTop: "0", marginBottom: "0" }}
          >
            <div className="column is-half">
              <div className="content aboutContentHome">
                <h2 className="title">About Our Club</h2>
                <p>
                  Where passion meets performance, and dedication fuel dreams.
                  At Varsinais-Suomen Nepalin Urheiluyhdistys ry [Formerly, FC
                  GURKHA], we're more than just a team, we're a family united by
                  our love for sports. Whether you're a seasoned athlete or just
                  starting your journey, you've found your home here.
                </p>
                <p>
                  Join us in our quest for excellence, where every triumph is
                  celebrated and every challenge is overcomed together. From the
                  thrill of the game to the camaraderie off the field, our club
                  offers an experience like no other.
                </p>
                <p>
                  Explore our training sessions, meet our players and athletes,
                  participate in our board meetings and discover what it means
                  to be part of something extraordinary. Together, let's write
                  the next chapter in our legacy of success. Welcome to the
                  heart of Nepalese residing in Turku, welcome to
                  Varsinais-Suomen Nepalin Urheiluyhdistys ry.
                </p>
                <Link to={"/about"} className="button is-link">
                  More About us
                </Link>
              </div>
            </div>
            <div className="column is-half">
              <figure className="image">
                <img src={handHoldSports} alt="Sports Club" loading="lazy" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section sponsors"
        style={{
          margin: "0 80px 5rem 80px",
          width: "auto",
          padding: "0",
          height: "400px",
        }}
      >
        <div className="container">
          <div className="columns is-vcentered sponsorColumn">
            <div className="column is-half">
              <figure className="image sponsorImage" height={"400px"}>
                <img
                  src={resimatorLogo}
                  alt="Sports Club"
                  style={{ maxWidth: "80%", height: "400px" }}
                  loading="lazy"
                />
              </figure>
            </div>

            <div className="column is-half">
              <div className="content sponsorTextContent">
                <h2 className="title">Featured Sponsor</h2>
                <p>
                  As we continue on our journey, we carry with us the profound
                  appreciation for <strong>Resimator Oy</strong>'s generosity
                  and friendship. Their support serves as a beacon of hope,
                  guiding us towards a future filled with endless possibilities
                  and boundless success. Their belief in our vision has fueled
                  our determination to push boundaries, break barriers, and make
                  a positive impact in the diaspora of Nepalese sports happening
                  around Turku region and Finland.
                </p>
                <p>
                  Thank you, <strong>Resimator Oy</strong>, for being an
                  integral part of our sports club family. Your support is truly
                  appreciated, and we look forward to many more years of
                  collaboration, partnership, and shared achievements.
                </p>
                <a
                  href="https://www.resimator.fi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button is-link">
                    More About Resimator Oy
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SponsorBanner />

      <section className="calendarWidget">
        <h2 className="title" style={{ marginBottom: "3rem" }}>
          Club Calendar
        </h2>
        <p className="subtitle is-4 calendarText">
          "Check out our club's monthly calendar for the latest events,<br></br>
          ground(indoor, outdoor) reservations and training schedule."
        </p>
        <div className="tabs is-centered widgetTab">
          <ul>
            <li
              className={showCalendar ? "is-active" : ""}
              onClick={toggleCalendarWidget}
            >
              <a href="#/">
                <span className="icon is-small">
                  <i className="fas fa-calendar" aria-hidden="true"></i>
                </span>
                <span>Calendar View</span>
              </a>
            </li>
            <li
              className={showEvents ? "is-active" : ""}
              onClick={toggleEventWidget}
            >
              <a href="#/">
                <span className="icon is-small">
                  <i className="fas fa-list" aria-hidden="true"></i>
                </span>
                <span>Event List View</span>
              </a>
            </li>
          </ul>
        </div>
        {/* <button className="button is-link is-rounded" onClick={toggleWidget}>
          {showCalendar ? "Show Events" : "Show Calendar"}
        </button> */}
        {showCalendar && <NimenhuutoCalendarWidget />}
        {showEvents && <NimenhuutoEventWidget />}
      </section>
    </div>
  );
};

export default Home;
