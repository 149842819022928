import React, { Component } from "react";

export default class DetailsAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };
  }

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  render() {
    return (
      <article className="message is-info" id="teams">
        <div className="message-header">
          <p>{this.props.teamName}</p>
          <button className="button is-small" onClick={this.toggleDetails}>
            {this.state.showDetails ? "-" : "+"}
          </button>
        </div>

        {this.state.showDetails && (
          <div className="message-body allteams-body">
            <p className="subtitle">
              Contact Number:
              <strong>
                <span className="tag is-link">{this.props.contactNumber}</span>
              </strong>
            </p>
            <p className="subtitle">
              Manager Name:
              <strong>
                <span className="tag is-link">{this.props.managerName}</span>
              </strong>
            </p>

            <div
              className="subtitle"
              id="getPlayers"
              style={{ paddingRight: "0" }}
            >
              <p
                className="subtitle"
                style={{ textAlign: "center", textDecoration: "underline" }}
              >
                Player's Name List:
              </p>
              <strong>
                <ol>
                  {this.props.team &&
                    this.props.playersName.map((myPlayer, p) => (
                      <li key={p}>
                        <span className="tag is-link">{myPlayer}</span>
                      </li>
                    ))}
                </ol>
              </strong>
            </div>
          </div>
        )}
      </article>
    );
  }
}
