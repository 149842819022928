/* import React, { Component } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Auth from "../auth/auth";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loggedIn: false,
      isLoading: true,
    };
    this.loginUsername = this.loginUsername.bind(this);
    this.loginPassword = this.loginPassword.bind(this);
  }

  loginUsername = (e) => {
    this.setState({ username: e.target.value });
  };
  loginPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  areFieldsValid = () => {
    if (this.state.username && this.state.password) {
      return true;
    } else {
      toast.error("Invalid or Empty Fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  };

  onLoginSubmit = (e) => {
    e.preventDefault();
    const login = {
      username: this.state.username,
      password: this.state.password,
    };
    if (this.areFieldsValid()) {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}authenticate`, login)
        .then((res) => {
          if (res.data.success === true) {
            localStorage.setItem("usertoken", JSON.stringify(res.data.token));
            localStorage.setItem("user", JSON.stringify(res.data.user));

            this.setState({ loggedIn: true });
            toast.success(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              username: "",
              password: "",
              loggedIn: false,
              isLoading: false,
            });
          } else if (res.data.success === false) {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              username: this.state.username,
              password: this.state.password,
              loggedIn: false,
              isLoading: false,
            });
          } else {
            toast.error(res.data.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
              username: this.state.username,
              password: this.state.password,
              loggedIn: false,
              isLoading: false,
            });
          }
        });
    }
  };

  render() {
    if ( Auth.isUserAuthenticated()) {
      return <Navigate to="/profile" />;
    }

    const loginform = (
      <div className="login">
        <section className="section">
          <div className="form">
            <label className="label">Username</label>
            <input
              className="input"
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={this.loginUsername}
            />
            <label className="label">Password</label>
            <input
              className="input"
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.loginPassword}
            />
            <br />
            <button
              className="button is-medium is-primary is-fullwidth"
              type="submit"
              onClick={this.onLoginSubmit}
            >
              Log In
            </button>
            <div className="forgotPwd">
              <Link to={"/forgotPassword"} className="link">
                Forgot Password?
              </Link>
            </div>

            <div className="divider">
              <hr className="left" />
              <span>OR</span>
              <hr className="right" />
            </div>
            <div className="registerBtn">
              <Link
                to={"/register"}
                className="button is-medium is-primary is-outlined"
              >
                Register
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
    return <>{loginform}</>;
  }
}
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isUserAuthenticated, setUser } = useAuth();
  const isAuthenticatedUser = isUserAuthenticated();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticatedUser || loggedIn) {
      navigate("/profile");
    }
  }, [isAuthenticatedUser, loggedIn, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const areFieldsValid = () => {
    const { username, password } = formData;
    if (!username.trim() || !password.trim()) {
      toast.error("Invalid or Empty Fields", {
        position: "top-center",
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    if (!areFieldsValid()) return;

    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}authenticate`,
        formData
      );
      const { success, msg, token, user } = res.data;

      if (success) {
        localStorage.setItem("usertoken", JSON.stringify(token));
        localStorage.setItem("user", JSON.stringify(user));

        toast.success(msg, {
          position: "top-center",
          theme: "colored",
        });
        setUser(user);
        setLoggedIn(true);
        setFormData({ username: "", password: "" });
      } else {
        toast.error(msg, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (error) {
      const response = error.response;
      if (response && response.data) {
        const { msg } = response.data;
        toast.error(msg, {
          position: "top-center",
          theme: "colored",
        });
      } else {
        toast.error("An unexpected error occurred", {
          position: "top-center",
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  /* if (isAuthenticatedUser || loggedIn) {
    return navigate("/profile");
  } */

  return (
    <div className="login">
      <section className="section">
        <div className="form">
          <label className="label">Username</label>
          <input
            className="input"
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
          />
          <label className="label">Password</label>
          <input
            className="input"
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
          />
          <br />
          <button
            className="button is-medium is-primary is-fullwidth"
            type="submit"
            onClick={onLoginSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Log In"}
          </button>
          <div className="forgotPwd">
            <Link to={"/forgotPassword"} className="link">
              Forgot Password?
            </Link>
          </div>
          <div className="divider">
            <hr className="left" />
            <span>OR</span>
            <hr className="right" />
          </div>
          <div className="registerBtn">
            <Link
              to={"/register"}
              className="button is-medium is-primary is-outlined"
            >
              Register
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
